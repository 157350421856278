import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import styles from './HomeScreen.module.css';
import config from '../../config';
import { useGlobalState } from '../../utils/hooks';
import { getMe } from '../../utils/api';
import { COOKIES } from '../../utils/constants';
import { ClickableDiv } from '../../components/atoms';

const SIGN_IN_METHOD = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
};

export default function Home() {
  const state = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    const cookie = new Cookies();
    const apiToken = cookie.get(COOKIES.API_TOKEN);

    const loadInfo = async () => {
      const res = await getMe({ apiToken });
      if (res.error || !res.user) {
        cookie.remove(COOKIES.API_TOKEN, { sameSite: 'none' });
      } else {
        state.apiToken = apiToken;
        state.user = res.user;
        cookie.set(COOKIES.API_TOKEN, apiToken, { path: '/', sameSite: 'none' });
        navigate('/dashboard');
      }
    };

    if (apiToken) {
      try {
        loadInfo();
      } catch (err) {
        cookie.remove(COOKIES.API_TOKEN, { sameSite: 'none' });
      }
    }
  }, []);

  const handleSignIn = (method) => {
    const redirectUrl = encodeURIComponent(`${window.location.href}login-complete`);
    return () => {
      if (method === SIGN_IN_METHOD.GOOGLE) {
        window.location.href = `${config.apiUrl}/auth/google?redirectUrl=${redirectUrl}`;
      } else if (method === SIGN_IN_METHOD.FACEBOOK) {
        window.location.href = `${config.apiUrl}/auth/facebook?redirectUrl=${redirectUrl}`;
      }
    };
  };

  return (
    <>
      <div className={styles.backgroundImage} />
      <div className={styles.container}>
        <div className={styles.signInContainer}>
          <span className={styles.logoText}>raduo</span>
          <ClickableDiv
            className={styles.googleButton}
            onClick={handleSignIn(SIGN_IN_METHOD.GOOGLE)}
          >
            <span className={styles.signInText}>Sign in With Google</span>
          </ClickableDiv>
          <ClickableDiv
            className={styles.facebookButton}
            onClick={handleSignIn(SIGN_IN_METHOD.FACEBOOK)}
          >
            <span className={styles.signInText}>Sign in With Facebook</span>
          </ClickableDiv>
        </div>
      </div>
    </>
  );
}
