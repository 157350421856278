import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  createQuestion,
  getQuestion,
  putSectionAnswers,
  updateQuestion,
} from '../../../utils/api';
import { useGlobalState } from '../../../utils/hooks';
import { QuestionType } from '../../../utils/constants';
import { SectionedPhoto } from '../../../components';
import { ClickableDiv } from '../../../components/atoms';
import styles from '../AdminNewOrEditQuestionScreen.module.css';

const DEFAULT_DIMENSION = 10;

export default function NewOrEditSectionPhotoQuestion({
  lessonId,
  questionId,
}) {
  const isEdit = questionId !== undefined;
  const { apiToken } = useGlobalState();
  const navigate = useNavigate();

  const [question, setQuestion] = useState();
  const [photoUrl, setPhotoUrl] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(isEdit);
  const [dimensionWidth, setDimensionWidth] = useState(DEFAULT_DIMENSION);
  const [dimensionHeight, setDimensionHeight] = useState(DEFAULT_DIMENSION);
  const [selectedCoordinates, setSelectedCoordinates] = useState([]);

  const isSmallWidth = window.innerWidth <= 500;
  const pictureScale = isSmallWidth ? 0.9 : 0.5;
  const pictureSize = Math.floor(window.innerWidth * pictureScale);

  if (isEdit) {
    const loadQuestionData = async () => {
      const res = await getQuestion({ apiToken, questionId });
      setQuestion(res.question.question);
      setPhotoUrl(res.question.photoUrl);
      setDimensionWidth(res.question.dimensionWidth);
      setDimensionHeight(res.question.dimensionHeight);
      const sectionAnswersAsCoordinates = res.question.sectionAnswers.map((sa) => ({
        x: sa.xDimension,
        y: sa.yDimension,
      }));
      setSelectedCoordinates(sectionAnswersAsCoordinates);
    };

    useEffect(() => {
      loadQuestionData()
        .then(() => setLoading(false))
        .catch((err) => {
          console.error('there was an error loading the existing question data');
          console.error(err);
          navigate(-1);
        });
    }, []);
  }

  if (loading) {
    return (
      <div className={styles.titleContainer}>
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleDimensionWidthChange = (e) => {
    let intVal = parseInt(e.target.value || 1, 10);
    if (intVal > 50) intVal = 50;
    if (intVal < 1) intVal = 1;
    setDimensionWidth(intVal);
  };

  const handleDimensionHeightChange = (e) => {
    let intVal = parseInt(e.target.value || 1, 10);
    if (intVal > 50) intVal = 50;
    if (intVal < 1) intVal = 1;
    setDimensionHeight(intVal);
  };

  const handleSubmit = async () => {
    const questionData = {
      type: QuestionType.SECTION_PHOTO,
      lessonId,
      question,
      photoUrl,
      dimensionWidth,
      dimensionHeight,
    };

    let sectionAnswerQuestionId = questionId;
    if (isEdit) {
      const res = await updateQuestion({ apiToken, questionId, updatedQuestionData: questionData });
      if (res.errors || res.error) {
        return setError(JSON.stringify(res.errors || res.error));
      }
    } else {
      const res = await createQuestion({ apiToken, questionData });
      if (res.errors || res.error) {
        return setError(`Error creating question: ${JSON.stringify(res.errors || res.error)}`);
      }
      sectionAnswerQuestionId = res.question.id;
    }

    await putSectionAnswers({
      apiToken,
      questionId: sectionAnswerQuestionId,
      correctCoordinates: selectedCoordinates,
    });
    return navigate(-1);
  };

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.label}>
          <span>Question*</span>
        </div>
        <input value={question} onChange={(e) => setQuestion(e.target.value)} />
      </div>
      <div className={styles.inputRow}>
        <div className={styles.label}>
          <span>Photo URL*</span>
        </div>
        <input value={photoUrl} onChange={(e) => setPhotoUrl(e.target.value)} />
      </div>
      <div className={styles.inputRow}>
        <div className={styles.label}>
          <span>Horizontal Sections*</span>
        </div>
        <input
          value={dimensionWidth}
          type="number"
          min="1"
          max="50"
          onChange={handleDimensionWidthChange}
        />
      </div>
      <div className={styles.inputRow}>
        <div className={styles.label}>
          <span>Vertical Sections*</span>
        </div>
        <input
          value={dimensionHeight}
          type="number"
          min="1"
          max="50"
          onChange={handleDimensionHeightChange}
        />
      </div>
      <div className={styles.sectionPhotoImageContainer}>
        {!!photoUrl && (
          <SectionedPhoto
            src={photoUrl}
            dimensionWidth={dimensionWidth}
            dimensionHeight={dimensionHeight}
            size={pictureSize}
            selected={selectedCoordinates}
            setSelected={setSelectedCoordinates}
          />
        )}
      </div>
      <div className={styles.createContainer}>
        <ClickableDiv onClick={handleSubmit}>
          <h3 className={styles.createButton}>{isEdit ? 'Update Question' : 'Create Question'}</h3>
        </ClickableDiv>
        {error !== undefined && (
          <span className={styles.errorText}>{error}</span>
        )}
      </div>
    </>
  );
}

NewOrEditSectionPhotoQuestion.propTypes = {
  lessonId: PropTypes.string.isRequired,
  questionId: PropTypes.string,
};

NewOrEditSectionPhotoQuestion.defaultProps = {
  questionId: undefined,
};
