import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { ClickableDiv } from '../../../components/atoms';
import { Visibility } from '../../../utils/constants';

import styles from './AdminPhotoQuestion.module.css';

export default function AdminPhotoQuestion({ question, handleMoveUp, handleMoveDown }) {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/admin/question/${question.id}/edit`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.numberContainer}>
        <h3>{question.position + 1}</h3>
        <ClickableDiv className={styles.arrow} onClick={() => handleMoveUp(question.position)}>
          <h4>▲</h4>
        </ClickableDiv>
        <ClickableDiv className={styles.arrow} onClick={() => handleMoveDown(question.position)}>
          <h4>▼</h4>
        </ClickableDiv>
        <ClickableDiv className={styles.arrow} onClick={handleEdit}>
          <h4>✎</h4>
        </ClickableDiv>
      </div>
      <div className={styles.questionContainer}>
        {question.visibility !== Visibility.PUBLIC && (
          <strong style={{ color: 'red' }}>{`${question.visibility}`}</strong>
        )}
        <span>{question.question}</span>
        <div className={styles.photoAndAnswers}>
          <img className={styles.image} alt={question.question} src={question.photoUrl} />
          <div className={styles.answers}>
            {question.answers.map((answer) => (
              <div>
                <span
                  className={answer.isCorrect ? styles.correctAnswer : styles.answer}
                >
                  {answer.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

AdminPhotoQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      isCorrect: PropTypes.bool.isRequired,
    })).isRequired,
    visibility: PropTypes.string.isRequired,
  }).isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
};
