import React from 'react';
import { Bars } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import styles from './LoadableContent.module.css';

export default function LoadableContent({ loading, children }) {
  if (loading) {
    return (
      <div className={styles.container}>
        <Bars color="#1b1b1b" height={50} width={50} />
      </div>
    );
  }
  return children;
}

LoadableContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};
