import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SideBySideMagnifier } from '@datobs/react-image-magnifiers';

import styles from './ImageZoomModal.module.css';
import { ClickableDiv } from '../atoms';
import { COLORS } from '../../utils/constants';

const IMAGE_HEIGHT_MAX_PCT = 0.8;
const IMAGE_WIDTH_MAX_PCT = 0.95;

export default function ImageZoomModal({ src, isOpen, onClose }) {
  const modalStyle = {
    display: isOpen ? undefined : 'none',
  };

  const [dimensions, setDimensions] = useState({ width: null, height: null });
  const heightRoom = window.innerHeight * IMAGE_HEIGHT_MAX_PCT;
  const widthRoom = window.innerWidth * IMAGE_WIDTH_MAX_PCT;

  const getImageWidth = () => {
    const { width, height } = dimensions;
    if (width === null || height === null) return 0;
    if (height <= heightRoom) return Math.min(widthRoom, width);
    const aspectRatio = width / height;
    const widthForMaxHeight = aspectRatio * heightRoom;
    return Math.min(widthForMaxHeight, widthRoom);
  };

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = ({ target: img }) => {
      const { naturalHeight, naturalWidth } = img;
      setDimensions({ width: naturalWidth, height: naturalHeight });
    };
  }, []);

  return (
    <>
      <div className={styles.modalShading} style={modalStyle} />
      <ClickableDiv
        className={styles.modalContainer}
        style={modalStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalContent}>
          <div className={styles.dismissContainer}>
            <ClickableDiv className={styles.closeButton} onClick={onClose}>
              <ion-icon style={{ fontSize: 32, color: COLORS.grey3 }} name="close" />
            </ClickableDiv>
          </div>
          <div className={styles.modalImageContainer}>
            <SideBySideMagnifier
              alwaysInPlace
              imageSrc={src}
              imageAlt="radiograph"
              style={{ width: getImageWidth() }}
            />
          </div>
          <div className={styles.okayContainer}>
            <ClickableDiv className={styles.okayButton} onClick={onClose}>
              <h3>Done</h3>
            </ClickableDiv>
          </div>
        </div>
      </ClickableDiv>
    </>
  );
}

ImageZoomModal.propTypes = {
  src: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
