const fetchHandleErrors = async (url, options = {}) => {
  const {
    apiToken,
    ...rest
  } = options;
  const headers = options.headers || {};
  if (apiToken !== undefined) {
    headers.Authorization = `Bearer ${apiToken}`;
  }
  const fetchOptions = {
    credentials: 'include',
    mode: 'cors',
    headers,
    ...rest,
  };
  if (options.body) {
    fetchOptions.body = JSON.stringify(options.body);
    fetchOptions.headers['Content-Type'] = 'application/json';
  }
  const fetchRes = await fetch(url, fetchOptions);

  try {
    const jsonRes = await fetchRes.json();
    return jsonRes;
  } catch (err) {
    return {
      error: {
        name: 'MalformedResponseError',
        message: 'The response was not JSON.',
      },
    };
  }
};

export default fetchHandleErrors;
