import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import styles from './LessonDidacticModal.module.css';
import Modal from '../Modal/Modal';
import { ClickableDiv } from '../atoms';

export default function LessonDidactic({ markdown, visible, setVisible }) {
  return (
    <Modal visible={visible} onClose={() => setVisible(false)} fullScreen>
      <div className={styles.container}>
        <div className={styles.markdownContent}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markdown}</ReactMarkdown>
        </div>
        <ClickableDiv className={styles.continueButton} onClick={() => setVisible(false)}>
          <h3 className={styles.continueText}>Continue to Lesson</h3>
        </ClickableDiv>
      </div>
    </Modal>
  );
}

LessonDidactic.propTypes = {
  markdown: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

LessonDidactic.defaultProps = {
  markdown: '# Loading...',
};
