import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getModule } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import { NavbarScreen, LoadableContent } from '../../components';
import LessonItem from './components/LessonItem';
import styles from './ModuleScreen.module.css';
import { Visibility } from '../../utils/constants';

export default function ModuleScreen() {
  const state = useGlobalState();
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const [module, setModule] = useState();
  const [lessons, setLessons] = useState([]);

  const lessonRows = [];
  lessons.forEach((lesson) => {
    const { level } = lesson;
    const existingLevel = lessonRows[level] || [];
    existingLevel.push(lesson);
    existingLevel.sort((a, b) => a.position - b.position);
    lessonRows[level] = existingLevel;
  });

  let unlockedLevel = 0;
  let stop = false;
  lessonRows.forEach((lessonsForLevel, i) => {
    if (!stop && lessonsForLevel.every(({ hasCompleted }) => hasCompleted)) {
      unlockedLevel = i + 1;
    } else {
      stop = true;
    }
  });

  useEffect(() => {
    async function loadModuleData() {
      const { apiToken } = state;
      const moduleRes = await getModule({
        apiToken,
        moduleId,
        lessonVisibilities: [
          Visibility.PUBLIC,
          Visibility.PREVIEW,
        ],
      });
      if (moduleRes.error || moduleRes.module === undefined) {
        navigate('/');
      } else {
        setModule(moduleRes.module);
        setLessons(moduleRes.lessons);
      }
    }

    loadModuleData();
  }, []);

  return (
    <NavbarScreen requireAuth>
      <LoadableContent loading={module === undefined}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1 className={styles.moduleTitle}>{module?.title}</h1>
          </div>
          <div className={styles.lessonRowsContainer}>
            {lessonRows.map((lessonRow, level) => (
              <div className={styles.lessonRow} key={lessonRow.map((lesson) => lesson.id).join('-')}>
                {lessonRow.map((lesson) => (
                  <LessonItem lesson={lesson} key={lesson.id} isUnlocked={level <= unlockedLevel} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </LoadableContent>
    </NavbarScreen>
  );
}
