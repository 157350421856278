import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ImageZoomModal, ZoomButton } from '../../../components';
import { ClickableDiv } from '../../../components/atoms';
import styles from './Answer.module.css';

export const AnswerState = {
  Neutral: 'NEUTRAL',
  Selected: 'SELECTED',
  Correct: 'CORRECT',
  Incorrect: 'INCORRECT',
};
export default function Answer({
  answer,
  onSelect,
  state,
}) {
  const handleAnswerClick = () => {
    onSelect(answer);
  };

  const [isZoomed, setZoomed] = useState(false);

  const classes = [styles.answerContainer];
  switch (state) {
    case AnswerState.Neutral:
      classes.push(styles.answerNeutral);
      break;
    case AnswerState.Selected:
      classes.push(styles.answerSelected);
      break;
    case AnswerState.Correct:
      classes.push(styles.answerCorrect);
      break;
    case AnswerState.Incorrect:
      classes.push(styles.answerIncorrect);
      break;
    default:
      break;
  }

  function handleZoomButtonClick(event) {
    if (event) {
      event.stopPropagation();
    }
    setZoomed(true);
  }

  function handleZoomClose(event) {
    if (event) {
      event.stopPropagation();
    }
    setZoomed(false);
  }

  if (answer.photoUrl === null) {
    return (
      <ClickableDiv className={classes.join(' ')} onClick={handleAnswerClick}>
        {answer.text !== null && <h4>{answer.text}</h4>}
      </ClickableDiv>
    );
  }
  return (
    <ClickableDiv className={classes.join(' ')} onClick={handleAnswerClick}>
      <div className={styles.answerImageContainer}>
        <img src={answer.photoUrl} className={styles.answerPhoto} alt="answer" />
        <ZoomButton
          className={styles.zoomButton}
          onClick={(e) => handleZoomButtonClick(e)}
        />
        <ImageZoomModal
          isOpen={isZoomed}
          src={answer.photoUrl}
          onClose={(e) => handleZoomClose(e)}
        />
      </div>
    </ClickableDiv>
  );
}

Answer.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    photoUrl: PropTypes.string,
    isCorrect: PropTypes.bool.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  state: PropTypes.oneOf(Object.values(AnswerState)),
};

Answer.defaultProps = {
  state: AnswerState.Neutral,
};
