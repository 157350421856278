import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { updateQuestion } from '../../../utils/api';
import { useGlobalState } from '../../../utils/hooks';
import styles from './AdminLessonQuestions.module.css';
import AdminTextQuestion from './AdminTextQuestion';
import AdminPhotoQuestion from './AdminPhotoQuestion';

export default function AdminLessonQuestions({ questions, refresh }) {
  const state = useGlobalState();
  const [moving, setMoving] = useState(false);

  const handleMoveUp = async (fromPosition) => {
    if (moving) return;
    if (fromPosition === 0) return;
    const toPosition = fromPosition - 1;

    setMoving(true);
    const { apiToken } = state;
    const sourceQuestion = questions.find((q) => q.position === fromPosition);
    const destinationQuestion = questions.find((q) => q.position === toPosition);
    if (!sourceQuestion || !destinationQuestion) return;

    // Move destination to temp position
    await updateQuestion({
      apiToken,
      questionId: destinationQuestion.id,
      updatedQuestionData: { position: -1 },
    });

    // Move source question up one
    await updateQuestion({
      apiToken,
      questionId: sourceQuestion.id,
      updatedQuestionData: { position: toPosition },
    });

    // Move destination question down one
    await updateQuestion({
      apiToken,
      questionId: destinationQuestion.id,
      updatedQuestionData: { position: fromPosition },
    });

    await refresh();
    setMoving(false);
  };

  const handleMoveDown = async (fromPosition) => {
    if (moving) return;
    if (fromPosition === questions.length - 1) return;
    const toPosition = fromPosition + 1;

    setMoving(true);

    const { apiToken } = state;
    const sourceQuestion = questions.find((q) => q.position === fromPosition);
    const destinationQuestion = questions.find((q) => q.position === toPosition);
    if (!sourceQuestion || !destinationQuestion) return;

    // Move destination to temp position
    await updateQuestion({
      apiToken,
      questionId: destinationQuestion.id,
      updatedQuestionData: { position: -1 },
    });

    // Move source question up one
    await updateQuestion({
      apiToken,
      questionId: sourceQuestion.id,
      updatedQuestionData: { position: toPosition },
    });

    // Move destination question down one
    await updateQuestion({
      apiToken,
      questionId: destinationQuestion.id,
      updatedQuestionData: { position: fromPosition },
    });

    await refresh();
    setMoving(false);
  };

  return (
    <div className={styles.container}>
      {questions.map((question) => {
        if (question.photoUrl === null) {
          return (
            <div className={styles.questionContainer}>
              <AdminTextQuestion
                key={question.id}
                question={question}
                handleMoveUp={handleMoveUp}
                handleMoveDown={handleMoveDown}
              />
            </div>
          );
        }
        return (
          <div className={styles.questionContainer}>
            <AdminPhotoQuestion
              key={question.id}
              question={question}
              handleMoveUp={handleMoveUp}
              handleMoveDown={handleMoveDown}
            />
          </div>
        );
      })}
    </div>
  );
}

AdminLessonQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
  })).isRequired,
  refresh: PropTypes.func.isRequired,
};
