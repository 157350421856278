import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { track } from '@amplitude/analytics-browser';

import { startActivity } from '../../../utils/api';
import styles from './LessonPopup.module.css';
import { ClickableDiv } from '../../../components/atoms';
import { useGlobalState } from '../../../utils/hooks';
import { Events } from '../../../utils/metric-events';

export default function LessonPopup({
  lesson,
  activityInProgress,
}) {
  const state = useGlobalState();
  const navigate = useNavigate();

  const activityExists = !!activityInProgress;
  const isComplete = activityInProgress?.isComplete || false;
  const isSuccess = lesson.hasCompleted;
  const isFailure = isComplete && !isSuccess;
  const isInProgress = activityExists && !isComplete;

  const { apiToken } = state;

  const onContinueLessonClick = async () => {
    track(Events.ContinueLesson, {
      lessonId: lesson.id,
      isInProgress,
      isSuccess,
      isFailure,
      activityExists,
    });
    state.currentActivity = activityInProgress;
    navigate(`/lesson/${lesson.id}`);
  };

  const onStartLessonClick = async () => {
    track(Events.StartLesson, {
      lessonId: lesson.id,
      isInProgress,
      isSuccess,
      isFailure,
      activityExists,
    });
    const { activity } = await startActivity({ apiToken, lessonId: lesson.id, create: true });
    state.currentActivity = activity;
    navigate(`/lesson/${lesson.id}`);
  };

  let scoreText = null;
  if (lesson.maxScore !== null) {
    scoreText = `Best Score: ${((lesson.maxScore) * 100).toFixed(0)}%`;
  }

  let detailText;
  let buttons = null;
  if (isInProgress) {
    detailText = 'You currently have an activity in progress for this lesson. You can resume from where you last left off, or start over, erasing your current progress.';
    buttons = (
      <>
        <ClickableDiv className={styles.button} onClick={onContinueLessonClick}>
          <h3>Continue Lesson</h3>
        </ClickableDiv>
        <ClickableDiv className={styles.button} onClick={onStartLessonClick}>
          <h3>Restart Lesson</h3>
        </ClickableDiv>
      </>
    );
  } else if (isFailure) {
    detailText = 'You didn\'t achieve a high enough score to pass the lesson. You can review your last attempt or try again.';
    buttons = (
      <>
        <ClickableDiv className={styles.button} onClick={onContinueLessonClick}>
          <h3>Review Lesson</h3>
        </ClickableDiv>
        <ClickableDiv className={styles.button} onClick={onStartLessonClick}>
          <h3>Restart Lesson</h3>
        </ClickableDiv>
      </>
    );
  } else if (isSuccess) {
    detailText = 'You\'ve already completed this lesson. You can review the lesson, or redo the lesson for practice.';
    buttons = (
      <>
        <ClickableDiv className={styles.button} onClick={onContinueLessonClick}>
          <h3>Review Lesson</h3>
        </ClickableDiv>
        <ClickableDiv className={styles.button} onClick={onStartLessonClick}>
          <h3>Practice Lesson</h3>
        </ClickableDiv>
      </>
    );
  } else {
    detailText = 'Click below to start the lesson. Good luck!';
    buttons = (
      <ClickableDiv className={styles.button} onClick={onStartLessonClick}>
        <h3>Start Lesson</h3>
      </ClickableDiv>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.description}>{lesson.description || lesson.title}</p>
      {detailText !== undefined && <p className={styles.detailText}>{detailText}</p>}
      {scoreText !== null && <p className={styles.scoreText}>{scoreText}</p>}
      {buttons}
    </div>
  );
}

LessonPopup.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    photoUrl: PropTypes.string.isRequired,
    hasCompleted: PropTypes.bool.isRequired,
    maxScore: PropTypes.number,
  }).isRequired,
  activityInProgress: PropTypes.shape({
    isComplete: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
  }),
};

LessonPopup.defaultProps = {
  activityInProgress: undefined,
};
