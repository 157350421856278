import fetch from './fetch';
import config from '../config';

const { apiUrl } = config;

// Auth

export const getAuthToken = () => {
  const tokenUrl = `${apiUrl}/auth/token`;
  return fetch(tokenUrl);
};

export const revoke = (apiToken) => {
  const tokenUrl = `${apiUrl}/auth/token`;
  return fetch(tokenUrl, {
    apiToken,
    method: 'DELETE',
  });
};

// Users

export const getMe = ({ apiToken }) => {
  const meUrl = `${apiUrl}/me`;
  return fetch(meUrl, { apiToken });
};

// Modules

export const getModules = ({ apiToken, visibilities }) => {
  let getModulesUrl = `${apiUrl}/modules`;
  if (Array.isArray(visibilities)) {
    const query = new URLSearchParams();
    visibilities.forEach((visibility) => {
      query.append('visibility', visibility);
    });
    getModulesUrl += `?${query.toString()}`;
  }
  return fetch(getModulesUrl, { apiToken });
};

export const getModule = ({ apiToken, moduleId, lessonVisibilities }) => {
  let getModuleUrl = `${apiUrl}/modules/${moduleId}`;
  if (Array.isArray(lessonVisibilities)) {
    const query = new URLSearchParams();
    lessonVisibilities.forEach((visibility) => {
      query.append('lessonVisibility', visibility);
    });
    getModuleUrl += `?${query.toString()}`;
  }
  return fetch(getModuleUrl, { apiToken });
};

export const createModule = ({ apiToken, moduleData }) => {
  const createModuleUrl = `${apiUrl}/modules`;
  return fetch(createModuleUrl, {
    apiToken,
    method: 'POST',
    body: moduleData,
  });
};

export const updateModule = ({ apiToken, moduleId, updatedModuleData }) => {
  const updateModuleUrl = `${apiUrl}/modules/${moduleId}`;
  return fetch(updateModuleUrl, {
    apiToken,
    method: 'PATCH',
    body: updatedModuleData,
  });
};

// Lessons

export const getLesson = ({ apiToken, lessonId, questionVisibilities }) => {
  let getLessonUrl = `${apiUrl}/lessons/${lessonId}`;
  if (Array.isArray(questionVisibilities)) {
    const query = new URLSearchParams();
    questionVisibilities.forEach((visibility) => {
      query.append('questionVisibility', visibility);
    });
    getLessonUrl += `?${query.toString()}`;
  }
  return fetch(getLessonUrl, { apiToken });
};

export const createLesson = ({ apiToken, lessonData }) => {
  const createLessonUrl = `${apiUrl}/lessons`;
  return fetch(createLessonUrl, {
    apiToken,
    method: 'POST',
    body: lessonData,
  });
};

export const updateLesson = ({ apiToken, lessonId, updatedLessonData }) => {
  const updateLessonUrl = `${apiUrl}/lessons/${lessonId}`;
  return fetch(updateLessonUrl, {
    apiToken,
    method: 'PATCH',
    body: updatedLessonData,
  });
};

export const putDidactic = ({ apiToken, lessonId, didacticMarkdown }) => {
  const putLessonDidacticUrl = `${apiUrl}/lessons/${lessonId}/didactic`;
  return fetch(putLessonDidacticUrl, {
    apiToken,
    method: 'PUT',
    body: {
      markdown: didacticMarkdown,
    },
  });
};

// Questions
export const getQuestion = ({ apiToken, questionId }) => {
  const getQuestionUrl = `${apiUrl}/questions/${questionId}`;
  return fetch(getQuestionUrl, {
    apiToken,
    method: 'GET',
  });
};

export const createQuestion = ({ apiToken, questionData }) => {
  const postQuestionUrl = `${apiUrl}/questions`;
  return fetch(postQuestionUrl, {
    apiToken,
    method: 'POST',
    body: questionData,
  });
};

export const updateQuestion = ({ apiToken, questionId, updatedQuestionData }) => {
  const patchQuestionUrl = `${apiUrl}/questions/${questionId}`;
  return fetch(patchQuestionUrl, {
    apiToken,
    method: 'PATCH',
    body: updatedQuestionData,
  });
};

// Answers
export const putAnswers = ({ apiToken, questionId, answerData }) => {
  const putAnswerUrl = `${apiUrl}/questions/${questionId}/answer`;
  return fetch(putAnswerUrl, {
    apiToken,
    method: 'PUT',
    body: {
      answers: answerData,
    },
  });
};

export const putSectionAnswers = ({ apiToken, questionId, correctCoordinates = [] }) => {
  const putSectionAnswerUrl = `${apiUrl}/questions/${questionId}/sectionAnswer`;
  return fetch(putSectionAnswerUrl, {
    apiToken,
    method: 'PUT',
    body: {
      correctCoordinates,
    },
  });
};

export const deleteAnswer = ({ apiToken, answerId }) => {
  const deleteAnswerUrl = `${apiUrl}/answer/${answerId}`;
  return fetch(deleteAnswerUrl, {
    apiToken,
    method: 'DELETE',
  });
};

// Activities
export const startActivity = ({ apiToken, lessonId, create = false }) => {
  const query = new URLSearchParams();
  query.set('create', create);
  const postActivityUrl = `${apiUrl}/activity?${query.toString()}`;
  return fetch(postActivityUrl, {
    apiToken,
    method: 'POST',
    body: { lessonId },
  });
};

export const getActivity = ({ apiToken, activityId }) => {
  const getActivityUrl = `${apiUrl}/activity/${activityId}`;
  return fetch(getActivityUrl, { apiToken });
};

export const guess = ({
  apiToken,
  activityId,
  questionId,
  answerId,
  guessLocations,
}) => {
  const postGuessUrl = `${apiUrl}/activity/${activityId}/guess`;

  const body = { questionId };
  if (answerId) body.answerId = answerId;
  if (guessLocations) body.guessLocations = guessLocations;
  return fetch(postGuessUrl, {
    apiToken,
    method: 'POST',
    body,
  });
};
