import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { track } from '@amplitude/analytics-browser';

import styles from './ModuleItem.module.css';
import { ClickableDiv } from '../../../components/atoms';
import { Visibility } from '../../../utils/constants';
import { Events } from '../../../utils/metric-events';

export default function Module({
  module,
}) {
  const navigate = useNavigate();

  const handleModuleClick = () => {
    track(Events.SelectModule, {
      moduleId: module.id,
      moduleVisibility: module.visibility,
    });
    if (module.visibility === Visibility.PUBLIC) {
      navigate(`/module/${module.id}`);
    }
  };

  const moduleClasses = classNames({
    [styles.contentContainer]: true,
    [styles.previewContentContainer]: module.visibility !== Visibility.PUBLIC,
    [styles.visibleContentContainer]: module.visibility === Visibility.PUBLIC,
  });

  return (
    <ClickableDiv className={styles.flexContainer} onClick={handleModuleClick}>
      <div className={moduleClasses}>
        <div className={styles.imageContainer}>
          <img className={styles.moduleImage} src={module.photoUrl} alt="module" />
        </div>
        <h3>{module.title}</h3>
        {module.visibility === Visibility.PREVIEW && (
          <h5>Coming Soon!</h5>
        )}
      </div>
    </ClickableDiv>
  );
}

Module.propTypes = {
  module: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    visibility: PropTypes.string.isRequired,
  }).isRequired,
};
