import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ClickableDiv } from '../atoms';
import styles from './ZoomButton.module.css';

export default function ZoomButton({
  onClick,
  size,
  className,
  style,
}) {
  const containerStyle = {
    borderRadius: size / 4,
    ...style,
  };

  const iconStyle = {
    fontSize: size,
  };

  const containerClasses = classNames({
    [styles.container]: true,
    [className]: className !== undefined,
  });

  return (
    <ClickableDiv
      onClick={onClick}
      className={containerClasses}
      style={containerStyle}
    >
      <ion-icon name="add-outline" style={iconStyle} />
    </ClickableDiv>
  );
}

ZoomButton.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

ZoomButton.defaultProps = {
  size: 38,
  className: undefined,
  style: {},
};
