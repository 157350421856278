import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { track } from '@amplitude/analytics-browser';

import { useGlobalState } from '../../../utils/hooks';
import { getActivity, guess } from '../../../utils/api';
import styles from './Question.module.css';

import Answer, { AnswerState } from './Answer';
import { ClickableDiv } from '../../../components/atoms';
import { ZoomButton, ImageZoomModal } from '../../../components';
import { Events } from '../../../utils/metric-events';

export default function TextQuestion({
  question,
  onNext,
  isLastQuestion,
  updateProgressBar,
}) {
  const state = useGlobalState();
  const { apiToken, currentActivity } = state;

  const activeGuess = currentActivity.guesses.find((g) => g.questionId === question.id);

  const [selectedAnswer, setSelectedAnswer] = useState(activeGuess?.answerId);
  const [questionGuess, setQuestionGuess] = useState(activeGuess);
  const [isZoomed, setZoomed] = useState(false);
  const submitted = questionGuess !== undefined;

  const handleAnswerSelect = (answerId) => () => {
    if (!submitted) {
      setSelectedAnswer(answerId);
    }
  };

  const getAnswerState = (answerId) => {
    if (questionGuess !== undefined && answerId === questionGuess.answerId) {
      if (questionGuess.isCorrect) {
        return AnswerState.Correct;
      }
      return AnswerState.Incorrect;
    }
    if (selectedAnswer === answerId) {
      return AnswerState.Selected;
    }
    return AnswerState.Neutral;
  };

  const nextButtonStyles = [styles.nextButton];
  if (!selectedAnswer) nextButtonStyles.push(styles.disabledButton);
  let nextButtonText = 'Submit Answer';
  if (submitted && !isLastQuestion) {
    nextButtonText = 'Next Question';
  } else if (submitted && isLastQuestion) {
    nextButtonText = 'View Summary';
  }

  const handleButtonClick = async () => {
    if (submitted) return onNext(isLastQuestion);

    track(Events.GuessAnswer, {
      questionId: question.id,
      questionType: 'PHOTO',
      lessonId: question.lessonId,
      activityId: currentActivity.id,
      answerId: selectedAnswer,
    });
    const { guesses } = await guess({
      apiToken,
      activityId: currentActivity.id,
      questionId: question.id,
      answerId: selectedAnswer,
    });
    const [newGuess] = guesses;

    setQuestionGuess(newGuess);
    const updatedActivity = await getActivity({
      apiToken,
      activityId: currentActivity.id,
    });
    state.currentActivity = updatedActivity.activity;
    updateProgressBar(shortid.generate());
    return null;
  };

  return (
    <div className={styles.questionContainer}>
      <div className={styles.questionImageContainer}>
        <img src={question.photoUrl} className={styles.questionImage} alt={question.question} />
        <ZoomButton
          className={styles.zoomButton}
          onClick={() => setZoomed(true)}
        />
        <ImageZoomModal
          isOpen={isZoomed}
          src={question.photoUrl}
          onClose={() => setZoomed(false)}
        />
      </div>
      <h2 className={styles.questionText}>{question.question}</h2>
      <div className={styles.answersContainer}>
        {question.answers.map((answer) => (
          <Answer
            key={answer.id}
            answer={answer}
            onSelect={handleAnswerSelect(answer.id)}
            state={getAnswerState(answer.id)}
          />
        ))}
      </div>
      <div className={styles.nextButtonContainer}>
        <ClickableDiv className={nextButtonStyles.join(' ')} onClick={handleButtonClick}>
          <h3>{nextButtonText}</h3>
        </ClickableDiv>
      </div>
    </div>
  );
}

TextQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    lessonId: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  }).isRequired,
  onNext: PropTypes.func.isRequired,
  isLastQuestion: PropTypes.bool,
  updateProgressBar: PropTypes.func.isRequired,
};

TextQuestion.defaultProps = {
  isLastQuestion: false,
};
