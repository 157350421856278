import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTimeout, useGlobalState } from '../../utils/hooks';
import {
  getActivity,
  getLesson,
  getModule,
  startActivity,
} from '../../utils/api';
import {
  NavbarScreen,
  LoadableContent,
  LessonDidacticModal,
} from '../../components';
import LessonProgressCircle from './components/LessonProgressCircle';

import styles from './LessonResultsScreen.module.css';

export default function LessonResultsScreen() {
  const { lessonId } = useParams();
  const navigate = useNavigate();

  const state = useGlobalState();
  const { apiToken, currentActivity } = state;

  const [activityInfo, setActivityInfo] = useState({});
  const [lesson, setLesson] = useState({});
  const [nextLessonId, setNextLessonId] = useState(null);
  const [didacticMarkdown, setDidacticMarkdown] = useState(null);
  const [showDidactic, setShowDidactic] = useState(false);
  const [actionsOpacity, setActionsOpacity] = useState(0);
  const { activity, stats } = activityInfo;

  useEffect(() => {
    const loadActivity = async () => {
      const res = await getActivity({
        apiToken,
        activityId: currentActivity.id,
      });
      setActivityInfo(res);
    };

    const loadLesson = async () => {
      const res = await getLesson({
        apiToken,
        lessonId: currentActivity.lessonId,
      });
      setDidacticMarkdown(res.didactic);
      setLesson(res.lesson);
    };

    loadActivity();
    loadLesson();
  }, []);

  useEffect(() => {
    const loadModule = async (moduleId) => {
      const { lessons } = await getModule({ apiToken, moduleId });
      const currentLessonIndex = lessons.findIndex(({ id }) => id === lessonId);
      const lastLesson = currentLessonIndex === lessons.length - 1;
      if (!lastLesson) {
        setNextLessonId(lessons[currentLessonIndex + 1].id);
      }
    };

    if (lesson?.moduleId) {
      loadModule(lesson.moduleId);
    }
  }, [lesson]);

  useTimeout(() => setActionsOpacity(1), 2000);

  const isPassing = stats?.isPassing || false;
  const resultText = isPassing
    ? 'Good Work!'
    : 'Almost! Give it another shot!';

  const lessonTitle = lesson?.title;
  const lessonPhotoUrl = lesson?.photoUrl;
  const moduleId = lesson?.moduleId;
  const numQuestions = stats?.questions;
  const numCorrect = stats?.correct;
  const score = stats?.score;

  async function handleTryAgainClick() {
    const { activity: newActivity } = await startActivity({ apiToken, lessonId, create: true });
    state.currentActivity = newActivity;
    navigate(`/lesson/${lessonId}`);
  }

  function handleBackToModuleClick() {
    navigate(`/module/${moduleId}`);
  }

  async function handleNextLessonClick() {
    if (!nextLessonId) return;

    const { activity: newActivity } = await startActivity({
      apiToken,
      lessonId: nextLessonId,
    });
    state.currentActivity = newActivity;
    navigate(`/lesson/${nextLessonId}`);
  }

  function handleReviewDidacticClick() {
    setShowDidactic(true);
  }

  return (
    <NavbarScreen requireAuth>
      <LessonDidacticModal
        markdown={didacticMarkdown}
        visible={didacticMarkdown !== null && showDidactic}
        setVisible={setShowDidactic}
      />
      <LoadableContent loading={activity === undefined}>
        <div className={styles.container}>
          <h1 className={styles.lessonTitle}>{lessonTitle}</h1>
          <div className={styles.circleContainer}>
            <LessonProgressCircle
              lessonPhotoUrl={lessonPhotoUrl}
              numQuestions={numQuestions}
              numCorrect={numCorrect}
              score={score}
              isPassing={isPassing}
            />
          </div>
          <div className={styles.resultTextContainer} style={{ opacity: actionsOpacity }}>
            <h2 className={styles.resultText}>{resultText}</h2>
            {!isPassing && (
              <h4 className={styles.resultInfoText}>
                Score over
                <strong> 80% </strong>
                to complete the course.
              </h4>
            )}
          </div>
          <div className={styles.buttonsContainer} style={{ opacity: actionsOpacity }}>
            {/* Next Lesson (if successful) */}
            {isPassing && (
              <button className={styles.passingButton} type="button" onClick={handleNextLessonClick}>
                Next Lesson
              </button>
            )}

            {/* Retry Lesson */}
            {!isPassing && (
              <button className={styles.failureButton} type="button" onClick={handleTryAgainClick}>
                Try Again
              </button>
            )}

            {/* Back To Module (always) */}
            <button className={styles.reviewButton} type="button" onClick={handleBackToModuleClick}>
              Back to Module
            </button>

            {/* Review Lesson Didactic (if present) */}
            {didacticMarkdown !== null && (
              <button className={styles.reviewButton} type="button" onClick={handleReviewDidacticClick}>
                Review Lesson Didactic
              </button>
            )}
          </div>
        </div>
      </LoadableContent>
    </NavbarScreen>
  );
}
