import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import classNames from 'classnames';
import { track } from '@amplitude/analytics-browser';

import styles from './LessonItem.module.css';
import { ClickableDiv } from '../../../components/atoms';
import { COLORS, Visibility } from '../../../utils/constants';

import LessonPopup from './LessonPopup';
import { Events } from '../../../utils/metric-events';

export default function LessonItem({ lesson, isUnlocked }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { latestActivity, bestActivity } = lesson;
  const navigationActivity = bestActivity || latestActivity || null;
  const hasActivity = latestActivity !== null;
  const isComplete = lesson.maxScore !== null;
  const isSuccess = lesson.hasCompleted;

  const sectionAlignments = [5, 1];

  const handleLessonClick = () => {
    if (lesson.visibility === Visibility.PUBLIC && isUnlocked) {
      track(Events.ShowLessonPreview, { lesson });
      setPopoverOpen(true);
    }
  };

  const lessonClasses = classNames({
    [styles.lessonItemContainer]: true,
    [styles.lessonItemVisible]: isUnlocked && lesson.visibility !== Visibility.PREVIEW,
    [styles.lessonItemPreview]: lesson.visibility === Visibility.PREVIEW,
    [styles.lessonItemLocked]: !isUnlocked,
    [styles.lessonComplete]: isUnlocked && isComplete && isSuccess,
    [styles.lessonInProgress]: isUnlocked && hasActivity && (!isComplete || !isSuccess),
  });

  return (
    <Popover
      isOpen={popoverOpen}
      positions={['bottom', 'top']}
      onClickOutside={() => {
        track(Events.DismissLessonPreview, { lesson });
        setPopoverOpen(false);
      }}
      parentElement={document.getElementById('root')}
      // eslint-disable-next-line
      content={({ position, childRect, popoverRect }) => {
        return (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowSize={10}
            arrowColor={COLORS.lightBlue}
          >
            <LessonPopup
              lesson={lesson}
              activityInProgress={navigationActivity}
              hasCompleted={isComplete}
            />
          </ArrowContainer>
        );
      }}
    >
      <div>
        <ClickableDiv
          className={lessonClasses}
          onClick={handleLessonClick}
        >
          <div className={styles.flexContainer} style={{ flex: sectionAlignments[0] }}>
            <img src={lesson.photoUrl} className={styles.lessonImage} alt={lesson.title} />
            <div className={styles.lessonTitleContainer}>
              {!isUnlocked && <h5 className={styles.lockedText}>🔒 LOCKED 🔒</h5>}
              <h4 className={styles.lessonTitle}>{lesson.title}</h4>
            </div>
            {lesson.visibility === Visibility.PREVIEW && (
              <h5>Coming Soon!</h5>
            )}
          </div>
          <div className={styles.flexContainer} style={{ flex: sectionAlignments[1] }} />
        </ClickableDiv>
      </div>
    </Popover>
  );
}

LessonItem.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    latestActivity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isComplete: PropTypes.bool.isRequired,
      isSuccess: PropTypes.bool.isRequired,
    }),
    bestActivity: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    visibility: PropTypes.string.isRequired,
    maxScore: PropTypes.number,
    hasCompleted: PropTypes.bool.isRequired,
  }).isRequired,
  isUnlocked: PropTypes.bool.isRequired,
};
