import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { useTimeout } from '../../../utils/hooks';
import { COLORS } from '../../../utils/constants';

import styles from './LessonProgressCircle.module.css';

export default function LessonProgressCircle({
  lessonPhotoUrl,
  numQuestions,
  numCorrect,
  score,
  isPassing,
}) {
  const percentScore = score * 100;

  const [scoreValue, setScoreValue] = useState(0);
  const [containerOpacity, setContainerOpacity] = useState(0);
  const [numericScoreOpacity, setNumericScoreOpacity] = useState(0);
  const [percentScoreOpacity, setPercentScoreOpacity] = useState(0);

  useTimeout(() => setContainerOpacity(1), 50);
  useTimeout(() => setScoreValue(percentScore), 500);
  useTimeout(() => setNumericScoreOpacity(1), 1000);
  useTimeout(() => setPercentScoreOpacity(1), 1500);

  const circleStyles = buildStyles({
    pathColor: isPassing ? COLORS.correct : COLORS.incorrect,
    strokeLinecap: 'round',
    pathTransitionDuration: 1.5,
    trailColor: COLORS.grey6,
  });

  return (
    <div className={styles.container} style={{ opacity: containerOpacity }}>
      <CircularProgressbarWithChildren
        styles={circleStyles}
        value={scoreValue}
        strokeWidth={2}
      >
        <div className={styles.contentContainer}>
          <div className={styles.imageContainer}>
            <img className={styles.lessonImage} src={lessonPhotoUrl} alt="lesson icon" />
          </div>
          <div className={styles.numericScoreContainer} style={{ opacity: numericScoreOpacity }}>
            <span className={styles.numericScore}>{`${numCorrect} / ${numQuestions}`}</span>
          </div>
          <div className={styles.percentScoreContainer} style={{ opacity: percentScoreOpacity }}>
            <span className={styles.percentScore}>{`(${percentScore.toFixed(0)}%)`}</span>
          </div>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

LessonProgressCircle.propTypes = {
  lessonPhotoUrl: PropTypes.string.isRequired,
  numQuestions: PropTypes.number.isRequired,
  numCorrect: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  isPassing: PropTypes.bool.isRequired,
};
