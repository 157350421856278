import React, { useState, useEffect, useRef } from 'react';
import { Oval } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import styles from './SectionedPhoto.module.css';
import { ClickableDiv } from '../atoms';
import { COLORS, opacity } from '../../utils/constants';

const AnswerState = {
  Correct: 'CORRECT',
  Incorrect: 'INCORRECT',
  Missed: 'MISSED',
};

const Colors = {
  Correct: opacity(COLORS.correct, '66'),
  Missed: opacity(COLORS.highlight, '66'),
  Incorrect: opacity(COLORS.incorrect, 'aa'),
  Selected: opacity(COLORS.gridBlue, 'aa'),
  Border: opacity(COLORS.gridBlue, 'aa'),
};

export default function SectionedPhoto({
  src,
  dimensionWidth,
  dimensionHeight,
  size,
  selected,
  setSelected,
  readOnly,
  answers,
}) {
  const showAnswers = Array.isArray(answers);

  const componentDidLoad = useRef(false);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    setLoadError(false);
    setLoading(true);
  }, [src]);

  useEffect(() => {
    if (componentDidLoad.current) {
      setSelected([]);
    } else {
      componentDidLoad.current = true;
    }
  }, [dimensionWidth, dimensionHeight]);

  if (loading) {
    const onImageLoad = () => {
      setLoading(false);
    };

    const onImageLoadError = () => {
      setLoadError(true);
      setLoading(false);
    };

    return (
      <div className={styles.loadingContainer}>
        <Oval width={50} height={50} />
        <img
          src={src}
          alt="loading"
          onLoad={onImageLoad}
          onError={onImageLoadError}
          style={{ display: 'none' }}
        />
      </div>
    );
  }

  if (loadError) {
    return (
      <div className={styles.container}>
        <h3>Invalid Image URL</h3>
      </div>
    );
  }

  const isIn = (arr = []) => (sx, sy) => arr.find(({ x, y }) => sx === x && sy === y) !== undefined;
  const isInSelected = isIn(selected);
  const isInAnswers = isIn(answers);

  const addToSelected = (x, y) => {
    const newSelected = [...selected];
    newSelected.push({ x, y });
    setSelected(newSelected);
  };

  const removeFromSelected = (x, y) => {
    const coordinateIndex = selected.findIndex((c) => c.x === x && c.y === y);
    const newSelected = [
      ...selected.slice(0, coordinateIndex),
      ...selected.slice(coordinateIndex + 1),
    ];
    setSelected(newSelected);
  };

  const handleSectionClick = (x, y) => () => {
    if (readOnly) return;
    const isSelected = isInSelected(x, y);

    if (!isSelected) {
      addToSelected(x, y);
    } else {
      removeFromSelected(x, y);
    }
  };

  const getAnswerState = (x, y) => {
    const isSelected = isInSelected(x, y);
    const isAnswer = isInAnswers(x, y);
    if (isSelected && isAnswer) return AnswerState.Correct;
    if (isSelected && !isAnswer) return AnswerState.Incorrect;
    if (!isSelected && isAnswer) return AnswerState.Missed;
    return null;
  };

  const styleFor = (x, y) => {
    const baseSectionStyle = {
      borderWidth: '0.5px',
      borderStyle: 'solid',
      borderColor: Colors.Border,
      cursor: 'pointer',
    };

    const coloredStyles = (color) => ({
      borderColor: color,
      backgroundColor: color,
    });

    if (showAnswers) {
      const answerState = getAnswerState(x, y);
      switch (answerState) {
        case AnswerState.Correct:
          return {
            ...baseSectionStyle,
            ...coloredStyles(Colors.Correct),
          };
        case AnswerState.Incorrect: {
          return {
            ...baseSectionStyle,
            ...coloredStyles(Colors.Incorrect),
          };
        }
        case AnswerState.Missed: {
          return {
            ...baseSectionStyle,
            ...coloredStyles(Colors.Missed),
          };
        }
        default:
          return baseSectionStyle;
      }
    }

    const isSelected = isInSelected(x, y);
    return {
      ...baseSectionStyle,
      background: isSelected ? Colors.Selected : undefined,
    };
  };

  const generatedGridStyle = {
    gridTemplateColumns: `repeat(${dimensionWidth}, auto)`,
    gridTemplateRows: `repeat(${dimensionHeight}, auto)`,
  };

  const widthArray = new Array(dimensionWidth).fill().map((a, i) => i);
  const heightArray = new Array(dimensionHeight).fill().map((a, i) => i);

  return (
    <div className={styles.container}>
      <img
        src={src}
        alt="sectioned question"
        className={styles.image}
        style={{ width: size }}
      />
      <div className={styles.overlayGrid} style={generatedGridStyle}>
        {heightArray.map((yCoordinate) => widthArray.map((xCoordinate) => (
          <ClickableDiv
            key={`${xCoordinate}-${yCoordinate}`}
            onClick={handleSectionClick(xCoordinate, yCoordinate)}
            style={styleFor(xCoordinate, yCoordinate)}
          />
        )))}
      </div>
    </div>
  );
}

const Coordinate = PropTypes.exact({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
});

SectionedPhoto.propTypes = {
  src: PropTypes.string.isRequired,
  dimensionWidth: PropTypes.number.isRequired,
  dimensionHeight: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(Coordinate).isRequired,
  setSelected: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  answers: PropTypes.arrayOf(Coordinate),
};

SectionedPhoto.defaultProps = {
  readOnly: false,
  answers: undefined,
};
