import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getModule } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import { NavbarScreen } from '../../components';
import { BackButton, ClickableDiv } from '../../components/atoms';
import AdminModuleLessons from './components/AdminModuleLessons';
import styles from './AdminSingleModuleScreen.module.css';
import { Visibility } from '../../utils/constants';

export default function AdminSingleModuleScreen() {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const state = useGlobalState();

  const [module, setModule] = useState();
  const [lessons, setLessons] = useState();

  useEffect(() => {
    const { apiToken } = state;
    getModule({
      apiToken,
      moduleId,
      lessonVisibilities: [
        Visibility.PUBLIC,
        Visibility.PREVIEW,
        Visibility.HIDDEN,
      ],
    })
      .then((res) => {
        setModule(res.module);
        setLessons(res.lessons);
      });
  }, []);

  if (module === undefined || lessons === undefined) {
    return (
      <NavbarScreen requireAuth>
        <h2>Loading Module...</h2>
      </NavbarScreen>
    );
  }

  const handleEditClick = () => {
    navigate('edit');
  };

  const handleAddLessonClick = () => {
    navigate('lesson/new');
  };

  return (
    <NavbarScreen requireAuth>
      <BackButton />
      <div className={styles.container}>
        <div className={styles.centeredContainer}>
          <h1>Module Info</h1>
        </div>
        <div className={styles.moduleInfoContainer}>
          <table className={styles.moduleInfoTable}>
            <tbody>
              <tr>
                <td><strong>Title</strong></td>
                <td>{module.title}</td>
              </tr>
              <tr>
                <td><strong>Visibility</strong></td>
                <td>{module.visibility}</td>
              </tr>
              <tr>
                <td><strong>Description</strong></td>
                <td>{module.description}</td>
              </tr>
              <tr>
                <td><strong>Photo URL</strong></td>
                <td>
                  <div>
                    <img src={module.photoUrl} className={styles.tableModulePhoto} alt="module" />
                    <em>{`(${module.photoUrl})`}</em>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.centeredContainer}>
            <ClickableDiv className={styles.editButton} onClick={handleEditClick}>
              <h2 className={styles.editText}>Edit</h2>
            </ClickableDiv>
          </div>
          <div className={styles.titleContainer}>
            <h1>Lessons</h1>
          </div>
          <AdminModuleLessons lessons={lessons} />
          <div className={styles.centeredContainer}>
            <ClickableDiv className={styles.editButton} onClick={handleAddLessonClick}>
              <h2 className={styles.editText}>Add Lesson</h2>
            </ClickableDiv>
          </div>
        </div>
      </div>
    </NavbarScreen>
  );
}
