import React from 'react';
import PropTypes from 'prop-types';

import Navbar from '../Navbar/Navbar';
import styles from './NavbarScreen.module.css';
import { useRequireAuth } from '../../utils/hooks';
import { ClickableDiv } from '../atoms';
import config from '../../config';

export default function NavbarScreen({ children, requireAuth }) {
  if (requireAuth) {
    useRequireAuth();
  }

  function handleFeedbackClick() {
    window.open(config.feedbackFormUrl, '_blank');
  }

  return (
    <div className={styles.outerContainer}>
      <Navbar />
      <div className={styles.contentContainer}>
        {children}
        <div style={{ flexGrow: 1 }} />
        <div className={styles.footer}>
          <ClickableDiv className={styles.feedbackButton} onClick={() => handleFeedbackClick()}>
            <h4 className={styles.feedbackText}>Submit Feedback</h4>
          </ClickableDiv>
        </div>
      </div>
    </div>
  );
}

NavbarScreen.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  requireAuth: PropTypes.bool,
};

NavbarScreen.defaultProps = {
  requireAuth: false,
};
