export const COOKIES = {
  API_TOKEN: 'raduo-api-token',
};

export const COLORS = {
  logo: '#0033cc',

  grey0: '#222222',
  grey1: '#444444',
  grey2: '#666666',
  grey3: '#888888',
  grey4: '#aaaaaa',
  grey5: '#cccccc',
  grey6: '#eeeeee',

  heading: '#222',

  blackBlue: '#020024',
  darkBlue: '#090979',
  blue: '#0033cc',
  lightBlue: '#c2d1ee',
  gridBlue: '#3190bc',
  modalOverlay: '#00000033',

  correct: '#0ad841',
  incorrect: '#bf6860',
  highlight: '#ffd700',
  red: '#ff7f42',
};

export const opacity = (color, opacityHex) => `${color}${opacityHex}`;

export const QuestionType = {
  PHOTO: 'PHOTO',
  TEXT: 'TEXT',
  SECTION_PHOTO: 'SECTION_PHOTO',
};

export const Visibility = {
  PUBLIC: 'PUBLIC',
  PREVIEW: 'PREVIEW',
  HIDDEN: 'HIDDEN',
};

export const Time = {
  SECONDS_IN_MINUTE: 60,
  SECONDS_IN_HOUR: 60 * 60,
  SECONDS_IN_DAY: 60 * 60 * 24,
  SECONDS_IN_YEAR: 60 * 60 * 24 * 365,
};
