import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import styles from './PopupQuestionModal.module.css';
import Modal from '../Modal/Modal';
import { ClickableDiv } from '../atoms';
import { Time } from '../../utils/constants';

const cookies = new Cookies();

export default function PopupQuestionModal({
  popupId,
  visible,
  question,
  options,
}) {
  const cookieName = `do-not-show-${popupId}`;
  const cookieOverride = cookies.get(cookieName);

  const [doNotShow] = useState(cookieOverride !== undefined);

  const handleCheckboxClick = (event) => {
    if (event.target.checked) {
      cookies.set(cookieName, true, {
        path: '/',
        maxAge: Time.SECONDS_IN_YEAR,
      });
    } else {
      cookies.remove(cookieName, { path: '/' });
    }
  };

  return (
    <Modal visible={!doNotShow && visible}>
      <div className={styles.popupModalContainer}>
        <h3 className={styles.questionText}>{question}</h3>
        <div className={styles.optionsContainer}>
          {options.map(({ text, onClick }) => (
            <ClickableDiv onClick={onClick} className={styles.optionButton} key={text}>
              <h4 className={styles.optionButtonText}>{text}</h4>
            </ClickableDiv>
          ))}
        </div>
        <div className={styles.showAgainContainer}>
          <input type="checkbox" onChange={handleCheckboxClick} />
          <h5>Do not show this popup again.</h5>
        </div>
      </div>
    </Modal>
  );
}

PopupQuestionModal.propTypes = {
  popupId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['CONFIRM', 'NEUTRAL', 'DENY']),
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
};
