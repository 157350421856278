import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import styles from '../AdminNewOrEditQuestionScreen.module.css';
import { ClickableDiv } from '../../../components/atoms';
import { QuestionType, Visibility } from '../../../utils/constants';
import { useGlobalState } from '../../../utils/hooks';
import {
  createQuestion,
  deleteAnswer,
  getQuestion,
  putAnswers,
  updateQuestion,
} from '../../../utils/api';

export default function NewOrEditPhotoQuestion({
  lessonId,
  questionId,
}) {
  const isEdit = questionId !== undefined;
  const { apiToken } = useGlobalState();
  const navigate = useNavigate();

  const [visibility, setVisibility] = useState(Visibility.PUBLIC);
  const [question, setQuestion] = useState();
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(isEdit);

  if (isEdit) {
    const loadQuestionData = async () => {
      const res = await getQuestion({ apiToken, questionId });
      setQuestion(res.question.question);
      setAnswers(res.question.answers);
      setVisibility(res.question.visibility);
    };

    useEffect(() => {
      loadQuestionData()
        .then(() => setLoading(false))
        .catch((err) => {
          console.error('there was an error loading the existing question data');
          console.error(err);
          navigate(-1);
        });
    }, []);
  }

  if (loading) {
    return (
      <div className={styles.titleContainer}>
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.value);
  };

  const handleAnswerPhotoUrlChange = (index) => (event) => {
    const newAnswer = {
      ...answers[index],
      photoUrl: event.target.value,
    };
    const newAnswers = [
      ...answers.slice(0, index),
      newAnswer,
      ...answers.slice(index + 1),
    ];
    setAnswers(newAnswers);
  };

  const handleAddAnswer = () => {
    const answersCopy = [...answers];
    answersCopy.push({
      id: shortid.generate(),
      isCorrect: false,
      isNew: true,
    });
    setAnswers(answersCopy);
  };

  const handleIsCorrectClick = (index) => (event) => {
    const newAnswer = {
      ...answers[index],
      isCorrect: event.target.checked,
    };
    const newAnswers = [
      ...answers.slice(0, index),
      newAnswer,
      ...answers.slice(index + 1),
    ];
    newAnswers.forEach((answer, i) => {
      if (event.target.checked && answer.isCorrect && i !== index) {
        answer.isCorrect = false;
      }
    });
    setAnswers(newAnswers);
  };

  const handleDelete = async (answer) => {
    const { id, isNew } = answer;
    if (!isNew) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      const res = await confirm('Are you sure you want to delete the answer? All data for this answer and user activities will be lost.');
      if (!res) return;
      await deleteAnswer({ apiToken, answerId: id });
    }

    const answerIndex = answers.findIndex((a) => a.id === id);
    const newAnswers = [
      ...answers.slice(0, answerIndex),
      ...answers.slice(answerIndex + 1),
    ];
    setAnswers(newAnswers);
  };

  const handleSubmit = async () => {
    const questionData = {
      type: QuestionType.PHOTO,
      question,
      lessonId,
      visibility,
    };

    let answerQuestionId = questionId;
    if (isEdit) {
      const res = await updateQuestion({ apiToken, questionId, updatedQuestionData: questionData });
      if (res.errors || res.error) {
        return setError(JSON.stringify(res.errors || res.error));
      }
    } else {
      const res = await createQuestion({ apiToken, questionData });
      if (res.errors || res.error) {
        return setError(`Error creating question: ${JSON.stringify(res.errors || res.error)}`);
      }
      answerQuestionId = res.question.id;
    }

    const answerData = answers.map((rawAnswer) => ({
      id: rawAnswer.isNew ? undefined : rawAnswer.id,
      isCorrect: rawAnswer.isCorrect,
      text: rawAnswer.text,
      photoUrl: rawAnswer.photoUrl,
    }));
    await putAnswers({ apiToken, questionId: answerQuestionId, answerData });

    return navigate(-1);
  };

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.label}>
          <span>Visibility*</span>
        </div>
        <select name="type" id="type-select" onChange={handleVisibilityChange} value={visibility}>
          <option value={Visibility.PUBLIC}>Public</option>
          <option value={Visibility.HIDDEN}>Hidden</option>
        </select>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.label}>
          <span>Question*</span>
        </div>
        <input value={question} onChange={(e) => setQuestion(e.target.value)} />
      </div>
      <div className={styles.titleContainer}>
        <h2>Answers</h2>
      </div>
      <div className={styles.answersContainer}>
        {answers.map((answer, i) => (
          <div key={answer.id} className={styles.answerContainer}>
            <div className={styles.inputRow}>
              <div className={styles.label}>
                <span>Photo URL*</span>
              </div>
              <input value={answer.photoUrl} onChange={handleAnswerPhotoUrlChange(i)} />
              {(answer.photoUrl !== undefined && answer.photoUrl !== '') && (
                <img className={styles.image} src={answer.photoUrl} alt="question icon" />
              )}
            </div>
            <div className={styles.inputRow}>
              <div className={styles.label}>
                <span>Is Correct?</span>
              </div>
              <input
                type="checkbox"
                checked={answer.isCorrect || false}
                onChange={handleIsCorrectClick(i)}
              />
              <div style={{ flexGrow: 1000 }} />
              <ClickableDiv className={styles.deleteButton} onClick={() => handleDelete(answer)}>
                <h4 className={styles.errorText}>Delete</h4>
              </ClickableDiv>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.titleContainer}>
        <ClickableDiv onClick={handleAddAnswer}>
          <h4 className={styles.createButton}>+ Add Answer</h4>
        </ClickableDiv>
      </div>
      <div className={styles.createContainer}>
        <ClickableDiv onClick={handleSubmit}>
          <h3 className={styles.createButton}>{isEdit ? 'Update Question' : 'Create Question'}</h3>
        </ClickableDiv>
        {error !== undefined && (
          <span className={styles.errorText}>{error}</span>
        )}
      </div>
    </>
  );
}

NewOrEditPhotoQuestion.propTypes = {
  lessonId: PropTypes.string.isRequired,
  questionId: PropTypes.string,
};

NewOrEditPhotoQuestion.defaultProps = {
  questionId: undefined,
};
