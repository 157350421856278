import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import { getModule, createModule, updateModule } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import { NavbarScreen } from '../../components';
import { ClickableDiv } from '../../components/atoms';
import styles from './AdminNewOrEditModuleScreen.module.css';
import { Visibility } from '../../utils/constants';

export default function AdminNewOrEditModuleScreen({ isEdit }) {
  const state = useGlobalState();
  const { moduleId } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [photoUrl, setPhotoUrl] = useState();
  const [loaded, setLoaded] = useState(!isEdit);
  const [visibility, setVisibility] = useState(Visibility.PUBLIC);
  const [error, setError] = useState();

  if (isEdit) {
    useEffect(() => {
      const { apiToken } = state;
      getModule({ apiToken, moduleId })
        .then((res) => {
          setTitle(res.module.title);
          setDescription(res.module.description);
          setPhotoUrl(res.module.photoUrl);
          setVisibility(res.module.visibility);
          setLoaded(true);
        });
    }, []);
  }

  if (!loaded) {
    return (
      <NavbarScreen requireAuth>
        <h1>Loading Module</h1>
      </NavbarScreen>
    );
  }

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.value);
  };

  const handleSubmit = () => {
    const moduleData = {
      title,
      description,
      photoUrl,
      visibility,
    };
    const { apiToken } = state;

    if (isEdit) {
      updateModule({ apiToken, moduleId, updatedModuleData: moduleData })
        .then((res) => {
          if (res.errors) {
            setError(JSON.stringify(res.errors));
          } else {
            navigate(-1);
          }
        });
    } else {
      createModule({ apiToken, moduleData })
        .then((res) => {
          if (res.errors) {
            setError(JSON.stringify(res.errors));
          } else {
            navigate(-1);
          }
        });
    }
  };

  return (
    <NavbarScreen requireAuth>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1>{isEdit ? 'Edit Module' : 'New Module'}</h1>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Visibility</span>
          </div>
          <select name="type" id="type-select" onChange={handleVisibilityChange} value={visibility}>
            <option value={Visibility.PUBLIC}>Public</option>
            <option value={Visibility.PREVIEW}>Preview</option>
            <option value={Visibility.HIDDEN}>Hidden</option>
          </select>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Title</span>
          </div>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Description</span>
          </div>
          <input value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Photo URL</span>
          </div>
          <input value={photoUrl} onChange={(e) => setPhotoUrl(e.target.value)} />
        </div>
        <div className={styles.createContainer}>
          <ClickableDiv onClick={handleSubmit}>
            <h3 className={styles.createButton}>{isEdit ? 'Update Module' : 'Create Module'}</h3>
          </ClickableDiv>
          {error !== undefined && (
            <span className={styles.errorText}>{error}</span>
          )}
        </div>
      </div>
    </NavbarScreen>
  );
}

AdminNewOrEditModuleScreen.propTypes = {
  isEdit: PropTypes.bool,
};

AdminNewOrEditModuleScreen.defaultProps = {
  isEdit: false,
};
