import React from 'react';
import ReactDOM from 'react-dom';
import { init } from '@amplitude/analytics-browser';

import './global.css';
import './variables.css';

import config from './config';
import App from './App';
import reportWebVitals from './reportWebVitals';

init(config.amplitudeApiKey, undefined, {
  serverUrl: `${config.apiUrl}/analytics`,
  flushIntervalMillis: 2500,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
