import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getModules } from '../../utils/api';
import { useEnsureIsLoggedIn, useGlobalState } from '../../utils/hooks';
import { NavbarScreen } from '../../components';
import { ClickableDiv } from '../../components/atoms';
import AdminModulesList from './components/AdminModulesList';
import styles from './AdminModulesScreen.module.css';
import { Visibility } from '../../utils/constants';

export default function AdminModulesScreen() {
  const [modules, setModules] = useState();
  const navigate = useNavigate();

  const state = useGlobalState();
  useEnsureIsLoggedIn(state);

  function loadModules() {
    const { apiToken } = state;
    getModules({
      apiToken,
      visibilities: [
        Visibility.PUBLIC,
        Visibility.PREVIEW,
        Visibility.HIDDEN,
      ],
    }).then((res) => {
      setModules(res.modules);
    });
  }

  useEffect(() => {
    loadModules();
  }, []);

  const handleAdd = () => {
    navigate('/admin/module/new');
  };

  return (
    <NavbarScreen requireAuth>
      <div className={styles.container}>
        <h1>Admin</h1>
        <h4>{`v${process.env.REACT_APP_VERSION}`}</h4>
        <div className={styles.displayHeader}>
          <h3 className={styles.displayTitle}>Modules</h3>
          <ClickableDiv onClick={() => loadModules()}>
            <h3 className={styles.refresh}>Refresh</h3>
          </ClickableDiv>
        </div>
        {modules !== undefined && (
          <AdminModulesList modules={modules} />
        )}
        <ClickableDiv onClick={handleAdd}>
          <h3 className={styles.add}>+ Add Module</h3>
        </ClickableDiv>
      </div>
    </NavbarScreen>
  );
}
