import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { putDidactic } from '../../../utils/api';
import { useGlobalState } from '../../../utils/hooks';
import { ClickableDiv } from '../../../components/atoms';
import styles from './AdminLessonDidactic.module.css';

export default function AdminLessonDidactic({ markdown, lessonId, refresh }) {
  const { apiToken } = useGlobalState();
  const [isEditing, setEditing] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [didacticMarkdown, setDidacticMarkdown] = useState(markdown);

  useEffect(() => {
    setDidacticMarkdown(markdown);
  }, [markdown]);

  useEffect(() => {
    if (isEditing) {
      setShowPreview(false);
    }
  }, [isEditing]);

  useEffect(() => {
    if (showPreview) {
      setEditing(false);
    }
  }, [showPreview]);

  function handlePreviewToggle() {
    setShowPreview(!showPreview);
  }

  function handleEditClick() {
    setEditing(true);
  }

  function handleMarkdownChange(e) {
    setDidacticMarkdown(e.target.value);
  }

  async function handleUpdate(remove = false) {
    await putDidactic({
      apiToken,
      lessonId,
      didacticMarkdown: remove ? null : didacticMarkdown,
    });
    refresh();
    setEditing(false);
    setShowPreview(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.centered}>
        {didacticMarkdown === null && <h2>None</h2>}
        {didacticMarkdown !== null && (
          <ClickableDiv className={styles.button} onClick={() => handlePreviewToggle()}>
            <h2 className={styles.buttonText}>{showPreview ? 'Hide' : 'Preview'}</h2>
          </ClickableDiv>
        )}
      </div>
      {showPreview && (
        <div className={styles.markdownContainer}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{didacticMarkdown}</ReactMarkdown>
        </div>
      )}
      {!isEditing && (
        <div className={styles.centered}>
          <ClickableDiv className={styles.button} onClick={() => handleEditClick()}>
            <h2 className={styles.buttonText}>{didacticMarkdown === null ? 'Add' : 'Edit'}</h2>
          </ClickableDiv>
        </div>
      )}
      {isEditing && (
        <>
          <div className={styles.textInputContainer}>
            <textarea value={didacticMarkdown} onChange={(e) => handleMarkdownChange(e)} />
          </div>
          <div className={styles.centered}>
            {!!didacticMarkdown && (
              <div className={styles.row}>
                <ClickableDiv className={styles.button} onClick={() => handleUpdate()}>
                  <h2 className={styles.buttonText}>Submit</h2>
                </ClickableDiv>
                <ClickableDiv className={styles.button} onClick={() => handleUpdate(true)}>
                  <h2 className={styles.deleteText}>Delete</h2>
                </ClickableDiv>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

AdminLessonDidactic.propTypes = {
  markdown: PropTypes.string,
  lessonId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

AdminLessonDidactic.defaultProps = {
  markdown: null,
};
