import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ClickableDiv } from '../../../components/atoms';
import { Visibility } from '../../../utils/constants';

import styles from './AdminModuleLessons.module.css';

export default function AdminModuleLessons({ lessons }) {
  const navigate = useNavigate();

  const handleLessonClick = (lessonId) => {
    navigate(`/admin/lesson/${lessonId}`);
  };

  return (
    <div className={styles.container}>
      {lessons.map((lesson, i) => (
        <ClickableDiv
          key={lesson.id}
          className={styles.lessonRow}
          style={{ borderTopWidth: i === 0 ? 1 : 0 }}
          onClick={() => handleLessonClick(lesson.id)}
        >
          <span className={styles.orderText}>{`${lesson.level} - ${lesson.position}`}</span>
          <img className={styles.lessonImage} src={lesson.photoUrl} alt="lesson" />
          <strong className={styles.titleText}>{lesson.title}</strong>
          {lesson.visibility !== Visibility.PUBLIC && (
            <span className={styles.visibilityText}>{`(${lesson.visibility})`}</span>
          )}
        </ClickableDiv>
      ))}
    </div>
  );
}

AdminModuleLessons.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
};
