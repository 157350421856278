import { useEffect, useContext } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { setUserId } from '@amplitude/analytics-browser';

import StateContext from '../context';
import { COOKIES } from './constants';
import { getMe } from './api';

export const useEnsureIsLoggedIn = (state) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.apiToken || !state.user) {
      navigate('/');
    }
  }, []);
};

export const useRequireAuth = async () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  if (!state.apiToken) {
    const cookie = new Cookies();
    const apiTokenCookie = cookie.get(COOKIES.API_TOKEN);
    if (!apiTokenCookie) {
      navigate('/');
    }
    state.apiToken = apiTokenCookie;
  }

  if (!state.user) {
    const meRes = await getMe({ apiToken: state.apiToken });
    if (meRes.error || !meRes.user) {
      navigate('/');
    }
    state.user = meRes.user;
    setUserId(meRes.user.id);
  }
};

export const useTimeout = (fn, delayMs) => {
  useEffect(() => {
    setTimeout(() => {
      fn();
    }, delayMs);
  }, []);
};

export const useGlobalState = () => useContext(StateContext);
