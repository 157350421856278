import React from 'react';
import PropTypes from 'prop-types';

import { useGlobalState } from '../../../utils/hooks';
import { ClickableDiv } from '../../../components/atoms';

import styles from './ProgressBar.module.css';

const SLIDE_DURATION_MS = 100;

export default function ProgressBar({
  questions,
  currentQuestionIndex,
  onSegmentClick,
}) {
  const { currentActivity } = useGlobalState();
  const maxQuestionIndex = currentActivity.guesses.length;

  function handleClick(index) {
    if (maxQuestionIndex < index) return;
    onSegmentClick(index);
  }

  const progressStyle = {
    width: `${(1 / questions.length) * 100}%`,
    transition: `transform ${SLIDE_DURATION_MS}ms linear`,
    transform: `translate(${100 * currentQuestionIndex}%, 0%)`,
  };

  return (
    <div className={styles.container}>
      <div className={styles.progressBarContainer}>
        {questions.map((q, i) => {
          const classes = [styles.progressSegmentBase];

          const guess = currentActivity.guesses.find((g) => g.questionId === q.id);
          let emoji = '';
          if (guess) {
            classes.push(styles.progressSegmentGuessed);
            if (guess.isCorrect) {
              emoji = '✅';
            } else {
              emoji = '❌';
            }
          }

          if (i <= maxQuestionIndex) {
            classes.push(styles.progressSegmentClickable);
          }
          return (
            <ClickableDiv key={q.id} className={classes.join(' ')} onClick={() => handleClick(i)}>
              <span className={styles.progressEmoji}>{emoji}</span>
            </ClickableDiv>
          );
        })}
      </div>
      <div className={styles.currentProgressContainer}>
        <div className={styles.currentProgress} style={progressStyle} />
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  currentQuestionIndex: PropTypes.number.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};
