import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import styles from './AdminModulesList.module.css';

import { ClickableDiv } from '../../../components/atoms';
import { Visibility } from '../../../utils/constants';

export default function AdminModulesList({ modules }) {
  const navigate = useNavigate();

  const handleModuleClick = (moduleId) => {
    navigate(`/admin/module/${moduleId}`);
  };

  const handleEdit = (e, moduleId) => {
    e.stopPropagation();
    navigate(`/admin/module/${moduleId}/edit`);
  };

  return (
    <div className={styles.container}>
      {modules.map((module) => (
        <ClickableDiv
          key={`module-${module.id}`}
          className={styles.moduleContainer}
          onClick={() => handleModuleClick(module.id)}
        >
          <img className={styles.image} src={module.photoUrl} alt="module" />
          <span className={styles.titleText}>{module.title}</span>
          {module.visibility !== Visibility.PUBLIC && (
            <span className={styles.visibilityText}>{`(${module.visibility})`}</span>
          )}
          <div style={{ flexGrow: 1 }} />
          <ClickableDiv onClick={(e) => handleEdit(e, module.id)}>
            <span className={styles.edit}>Edit</span>
          </ClickableDiv>
        </ClickableDiv>
      ))}
    </div>
  );
}

AdminModulesList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};
