import React, { useMemo } from 'react';
import {
  BrowserRouter,
  Routes,
  Navigate,
  Route,
} from 'react-router-dom';

import StateContext from './context';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import LoginCompleteScreen from './screens/LoginCompleteScreen/LoginCompleteScreen';
import DashboardScreen from './screens/DashboardScreen/DashboardScreen';
import ModuleScreen from './screens/ModuleScreen/ModuleScreen';
import LessonScreen from './screens/LessonScreen/LessonScreen';
import LessonResultScreen from './screens/LessonResultsScreen/LessonResultsScreen';

import AdminModulesScreen from './screens/AdminModulesScreen/AdminModulesScreen';
import AdminSingleModuleScreen from './screens/AdminSingleModuleScreen/AdminSingleModuleScreen';
import AdminNewOrEditModuleScreen from './screens/AdminNewOrEditModuleScreen/AdminNewOrEditModuleScreen';
import AdminSingleLessonScreen from './screens/AdminSingleLessonScreen/AdminSingleLessonScreen';
import AdminNewOrEditLessonScreen from './screens/AdminNewOrEditLessonScreen/AdminNewOrEditLessonScreen';
import AdminNewOrEditQuestionScreen from './screens/AdminNewOrEditQuestionScreen/AdminNewOrEditQuestionScreen';

export default function App() {
  return (
    <StateContext.Provider value={useMemo(() => ({ apiToken: undefined }))}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/login-complete" element={<LoginCompleteScreen />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/module/:moduleId" element={<ModuleScreen />} />
          <Route path="/lesson/:lessonId" element={<LessonScreen />} />
          <Route path="/lesson/:lessonId/results" element={<LessonResultScreen />} />

          {/* Admin Pages */}
          <Route exact path="/admin" element={<Navigate to="/admin/modules" />} />
          <Route path="/admin/modules" element={<AdminModulesScreen />} />
          <Route path="/admin/module/new" element={<AdminNewOrEditModuleScreen />} />
          <Route path="/admin/module/:moduleId/lesson/new" element={<AdminNewOrEditLessonScreen />} />
          <Route path="/admin/module/:moduleId/edit" element={<AdminNewOrEditModuleScreen isEdit />} />
          <Route path="/admin/module/:moduleId" element={<AdminSingleModuleScreen />} />
          <Route path="/admin/lesson/:lessonId/question/new" element={<AdminNewOrEditQuestionScreen />} />
          <Route path="/admin/lesson/:lessonId/edit" element={<AdminNewOrEditLessonScreen isEdit />} />
          <Route path="/admin/lesson/:lessonId" element={<AdminSingleLessonScreen />} />
          <Route path="/admin/question/:questionId/edit" element={<AdminNewOrEditQuestionScreen isEdit />} />
        </Routes>
      </BrowserRouter>
    </StateContext.Provider>
  );
}
