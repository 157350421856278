import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { COLORS } from '../../utils/constants';
import { ClickableDiv } from '../atoms';
import styles from './Modal.module.css';

export default function Modal({
  visible,
  children,
  onClose,
  fullScreen,
}) {
  const contentClasses = classNames({
    [styles.content]: true,
    [styles.fullScreenContent]: fullScreen,
    [styles.popupContent]: !fullScreen,
  });

  return (
    <>
      <div className={styles.shading} style={{ display: visible ? undefined : 'none' }} />
      <div className={contentClasses} style={{ display: visible ? undefined : 'none' }}>
        {fullScreen && (
          <div className={styles.closeContainer}>
            <ClickableDiv className={styles.closeButton} onClick={onClose}>
              <ion-icon style={{ fontSize: 32, color: COLORS.red }} name="close" />
            </ClickableDiv>
          </div>
        )}
        {children}
      </div>
    </>
  );
}

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: () => { },
  fullScreen: false,
};
