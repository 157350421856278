import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

import {
  getActivity,
  getLesson,
  startActivity,
} from '../../utils/api';
import { delay, shuffle } from '../../utils/functions';
import { useGlobalState } from '../../utils/hooks';
import { QuestionType } from '../../utils/constants';
import {
  NavbarScreen, LoadableContent, LessonDidacticModal, PopupQuestionModal,
} from '../../components';
import { ClickableDiv } from '../../components/atoms';
import TextQuestion from './components/TextQuestion';
import SectionPhotoQuestion from './components/SectionPhotoQuestion';
import PhotoQuestion from './components/PhotoQuestion';
import ProgressBar from './components/ProgressBar';
import styles from './LessonScreen.module.css';
import { Events } from '../../utils/metric-events';

const FADE_DURATION_MS = 100;

export default function LessonScreen() {
  const state = useGlobalState();
  const { apiToken, currentActivity } = state;
  const navigate = useNavigate();
  const { lessonId } = useParams();

  const [lesson, setLesson] = useState();
  const [, updateProgressBar] = useState();

  const numGuesses = currentActivity?.guesses?.length || 0;
  const [showPopup, setShowPopup] = useState(numGuesses === 0);
  const [showDidactic, setShowDidactic] = useState(false);
  const [didacticMarkdown, setDidacticMarkdown] = useState(null);

  const lessonComplete = currentActivity?.isComplete || false;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(lessonComplete ? 0 : numGuesses);
  const [questionHidden, setQuestionHidden] = useState(false);

  const questions = lesson?.questions || [];

  if (questions.length !== 0 && currentQuestionIndex === questions.length) {
    setCurrentQuestionIndex(0);
  }

  useEffect(() => {
    async function loadLessonData() {
      const dataPromises = [
        getLesson({ apiToken, lessonId }),
      ];
      if (currentActivity) {
        dataPromises.push(getActivity({ apiToken, activityId: currentActivity.id }));
      } else {
        dataPromises.push(startActivity({ apiToken, lessonId }));
      }

      const [lessonRes, activityRes] = await Promise.all(dataPromises);
      if (lessonRes.error || lessonRes.lesson === undefined) {
        return navigate('/');
      }

      state.currentActivity = activityRes.activity;
      setCurrentQuestionIndex(activityRes.activity?.guesses?.length || 0);

      const numQuestions = lessonRes.lesson.questions.length;
      if (numQuestions === numGuesses || currentQuestionIndex === numQuestions) {
        setCurrentQuestionIndex(0);
      }

      // Preload all images and shuffle answers
      lessonRes.lesson.questions.forEach((q) => {
        if (q.type === QuestionType.PHOTO) {
          q.answers.forEach((answer) => {
            const loader = new Image();
            loader.src = answer.photoUrl;
          });
        } else {
          const loader = new Image();
          loader.src = q.photoUrl;
        }
        shuffle(q.answers);
      });

      setDidacticMarkdown(lessonRes.didactic);
      return setLesson(lessonRes.lesson);
    }

    loadLessonData();
  }, []);

  async function updateQuestionIndex(newIndex) {
    track(Events.GoToQuestion, {
      questionIndex: newIndex,
      questionId: questions[newIndex]?.id,
      lessonId: lesson.id,
    });
    if (newIndex === currentQuestionIndex) return;
    setQuestionHidden(true);
    await delay(FADE_DURATION_MS);
    setCurrentQuestionIndex(newIndex);
    setQuestionHidden(false);
  }

  const nextQuestion = (isLastQuestion) => {
    if (isLastQuestion) {
      navigate(`/lesson/${lesson.id}/results`);
    } else {
      updateQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const Questions = questions.map((question, i) => {
    const isLastQuestion = i === questions.length - 1;
    switch (question.type) {
      case QuestionType.TEXT:
        return (
          <TextQuestion
            key={question.id}
            question={question}
            onNext={nextQuestion}
            isLastQuestion={isLastQuestion}
            updateProgressBar={updateProgressBar}
          />
        );
      case QuestionType.PHOTO:
        return (
          <PhotoQuestion
            key={question.id}
            question={question}
            onNext={nextQuestion}
            isLastQuestion={isLastQuestion}
            updateProgressBar={updateProgressBar}
          />
        );
      case QuestionType.SECTION_PHOTO:
        return (
          <SectionPhotoQuestion
            key={question.id}
            question={question}
            onNext={nextQuestion}
            isLastQuestion={isLastQuestion}
            updateProgressBar={updateProgressBar}
          />
        );
      default:
        return null;
    }
  });

  const popupQuestionOptions = [
    {
      text: 'View Didactic',
      onClick: () => {
        setShowPopup(false);
        setShowDidactic(true);
      },
    },
    {
      text: 'Continue to Lesson',
      onClick: () => {
        setShowPopup(false);
      },
    },
  ];

  const questionStyles = [styles.questionContainer];
  if (questionHidden) questionStyles.push(styles.questionHidden);

  return (
    <NavbarScreen requireAuth>
      <PopupQuestionModal
        popupId="lesson-didactic-question-popup"
        visible={showPopup}
        question="Would you like to open the lesson didactic? Or continue to the lesson?"
        options={popupQuestionOptions}
      />
      <LessonDidacticModal
        markdown={didacticMarkdown}
        visible={didacticMarkdown !== null && showDidactic}
        setVisible={setShowDidactic}
      />
      <LoadableContent loading={lesson === undefined || currentActivity === undefined}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.iconContainer} />
              <h1 className={styles.lessonTitle}>{lesson?.title}</h1>
              <div className={styles.iconContainer}>
                {didacticMarkdown !== null && (
                  <ClickableDiv className={styles.iconButton} onClick={() => setShowDidactic(true)}>
                    <ion-icon name="help-circle-outline" style={{ fontSize: 30 }} />
                  </ClickableDiv>
                )}
              </div>
            </div>
            <ProgressBar
              questions={questions}
              currentQuestionIndex={currentQuestionIndex}
              onSegmentClick={(i) => updateQuestionIndex(i)}
            />
          </div>
          <div className={questionStyles.join(' ')} style={{ transition: `opacity ${FADE_DURATION_MS}ms` }}>
            {Questions[currentQuestionIndex]}
          </div>
        </div>
      </LoadableContent>
    </NavbarScreen>
  );
}
