import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { track } from '@amplitude/analytics-browser';

import styles from './Question.module.css';
import {
  SectionedPhoto, ZoomButton, ImageZoomModal, PopupQuestionModal,
} from '../../../components';
import { ClickableDiv } from '../../../components/atoms';
import { getActivity, guess } from '../../../utils/api';
import { useGlobalState } from '../../../utils/hooks';
import { Events } from '../../../utils/metric-events';

const QuestionState = {
  Interactive: 'INTERACTIVE',
  Loading: 'LOADING',
  Submitted: 'SUBMITTED',
};

const sectionToCoordinate = (sectionAnswer) => ({
  x: sectionAnswer.xDimension,
  y: sectionAnswer.yDimension,
});

export default function SectionPhotoQuestion({
  question,
  onNext,
  isLastQuestion,
  updateProgressBar,
}) {
  const state = useGlobalState();
  const { apiToken, currentActivity } = state;

  const sectionGuessesForQuestion = currentActivity.guesses
    .find((sg) => sg.questionId === question.id);
  const hasGuessed = sectionGuessesForQuestion !== undefined;
  const guessedCoordinates = hasGuessed
    ? sectionGuessesForQuestion.guesses
    : [];
  const [selectedCoordinates, setSelectedCoordinates] = useState(guessedCoordinates);

  const initialQuestionState = hasGuessed ? QuestionState.Submitted : QuestionState.Interactive;
  const [questionState, setQuestionState] = useState(initialQuestionState);

  const [isZoomed, setZoomed] = useState(false);
  const [pictureWidth, setPictureWidth] = useState(Math.floor(window.innerHeight * 0.6));

  const [showPopup, setShowPopup] = useState(!hasGuessed);

  useEffect(() => {
    const image = new Image();
    image.src = question.photoUrl;

    image.onload = ({ target: img }) => {
      const { naturalHeight, naturalWidth } = img;
      const maxWidth = window.innerWidth * 0.95;
      const maxHeight = window.innerHeight * 0.6;

      if (naturalHeight <= maxWidth) {
        setPictureWidth(Math.min(maxWidth, naturalWidth));
      } else {
        const aspectRatio = naturalWidth / naturalHeight;
        const widthForMaxHeight = aspectRatio * maxHeight;
        setPictureWidth(Math.min(widthForMaxHeight, maxWidth));
      }
    };
  }, []);

  useEffect(() => {

  }, []);

  const makeGuess = async () => {
    if (selectedCoordinates.length === 0) return;

    track(Events.GuessAnswer, {
      questionId: question.id,
      questionType: 'SECTION PHOTO',
      lessonId: question.lessonId,
      activityId: currentActivity.id,
      guessLocations: selectedCoordinates,
    });
    await guess({
      apiToken,
      activityId: currentActivity.id,
      questionId: question.id,
      guessLocations: selectedCoordinates,
    });

    const updatedActivity = await getActivity({
      apiToken,
      activityId: currentActivity.id,
    });
    state.currentActivity = updatedActivity.activity;
    updateProgressBar(shortid.generate());
    setQuestionState(QuestionState.Submitted);
  };

  const handleButtonClick = () => {
    switch (questionState) {
      case QuestionState.Interactive:
        if (selectedCoordinates.length === 0) break;
        makeGuess();
        break;
      case QuestionState.Submitted:
        onNext(isLastQuestion);
        break;
      default:
        break;
    }
  };

  const nextButtonStyles = [styles.nextButton];
  let nextButtonText = 'Submit Answer';

  switch (questionState) {
    case QuestionState.Interactive:
      if (selectedCoordinates.length === 0) {
        nextButtonStyles.push(styles.disabledButton);
      }
      break;
    case QuestionState.Loading:
      nextButtonStyles.push(styles.disabledButton);
      nextButtonText = 'Submitting...';
      break;
    case QuestionState.Submitted:
      nextButtonText = isLastQuestion ? 'View Summary' : 'Next Question';
      break;
    default:
      break;
  }

  const sectionedPhotoAnswers = hasGuessed
    ? question.sectionAnswers.map(sectionToCoordinate)
    : undefined;

  function handleZoomButtonClick(event) {
    if (event) {
      event.stopPropagation();
    }
    setZoomed(true);
  }

  function handleZoomClose(event) {
    if (event) {
      event.stopPropagation();
    }
    setZoomed(false);
  }

  return (
    <>
      <PopupQuestionModal
        popupId="section-photo-info"
        visible={showPopup}
        question="This is a grid question. Select all the boxes that contain any portion of the item of interest, but no extra!"
        options={[{
          text: 'Okay',
          onClick: () => setShowPopup(false),
        }]}
      />
      <div className={styles.questionContainer}>
        <h2 className={styles.questionText}>{question.question}</h2>
        <div className={styles.sectionPhotoContainer}>
          <SectionedPhoto
            src={question.photoUrl}
            dimensionWidth={question.dimensionWidth}
            dimensionHeight={question.dimensionHeight}
            size={pictureWidth}
            selected={selectedCoordinates}
            setSelected={setSelectedCoordinates}
            readOnly={questionState !== QuestionState.Interactive}
            answers={sectionedPhotoAnswers}
          />
          <ZoomButton
            className={styles.zoomButtonSectioned}
            onClick={(e) => handleZoomButtonClick(e)}
          />
          <ImageZoomModal
            isOpen={isZoomed}
            src={question.photoUrl}
            onClose={(e) => handleZoomClose(e)}
          />
        </div>
        <div className={styles.nextButtonContainer}>
          <ClickableDiv className={nextButtonStyles.join(' ')} onClick={handleButtonClick}>
            <h3>{nextButtonText}</h3>
          </ClickableDiv>
        </div>
      </div>
    </>
  );
}

SectionPhotoQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    lessonId: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    dimensionWidth: PropTypes.number.isRequired,
    dimensionHeight: PropTypes.number.isRequired,
    sectionAnswers: PropTypes.arrayOf(PropTypes.shape({
      xDimension: PropTypes.number.isRequired,
      yDimension: PropTypes.number.isRequired,
    })),
  }).isRequired,
  onNext: PropTypes.func.isRequired,
  isLastQuestion: PropTypes.bool,
  updateProgressBar: PropTypes.func.isRequired,
};

SectionPhotoQuestion.defaultProps = {
  isLastQuestion: false,
};
