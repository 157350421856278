import React, { useEffect, useState } from 'react';

import { getModules } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import { NavbarScreen, LoadableContent } from '../../components';
import ModuleItem from './components/ModuleItem';
import styles from './DashboardScreen.module.css';
import { Visibility } from '../../utils/constants';

export default function DashboardScreen() {
  const state = useGlobalState();

  const [modules, setModules] = useState();

  const { apiToken } = state;

  useEffect(() => {
    async function loadModules() {
      const modulesResponse = await getModules({
        apiToken,
        visibilities: [Visibility.PUBLIC, Visibility.PREVIEW],
      });
      if (modulesResponse.error) {
        return;
      }

      setModules(modulesResponse.modules);
    }

    loadModules();
  }, []);

  return (
    <NavbarScreen requireAuth>
      <LoadableContent loading={modules === undefined}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1 className={styles.headerText}>Welcome to Raduo! Select a module to get started.</h1>
          </div>
          <div className={styles.modulesContainer}>
            {modules?.map((module) => <ModuleItem key={module.id} module={module} />)}
          </div>
          <div style={{ flexGrow: 1 }} />
          <a
            className={styles.attributionLink}
            href="https://www.flaticon.com/authors/freepik"
            title="freepik icons"
          >
            Icons created by Freepik - Flaticon
          </a>
        </div>
      </LoadableContent>
    </NavbarScreen>
  );
}
