import React from 'react';
import PropTypes from 'prop-types';

import styles from './AdminLessonInfoTable.module.css';

export default function AdminLessonInfoTable({ lesson }) {
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td><strong>Title</strong></td>
          <td>{lesson.title}</td>
        </tr>
        <tr>
          <td><strong>Visibility</strong></td>
          <td>{lesson.visibility}</td>
        </tr>
        <tr>
          <td><strong>Description</strong></td>
          <td>{lesson.description || '(none)'}</td>
        </tr>
        <tr>
          <td><strong>Ordering</strong></td>
          <td className={styles.mono}>{`${lesson.level} - ${lesson.position}`}</td>
        </tr>
        <tr>
          <td><strong>Photo URL</strong></td>
          <td>
            <div className={styles.centeredRow}>
              <img src={lesson.photoUrl} className={styles.photo} alt="lesson" />
              <em>{`(${lesson.photoUrl})`}</em>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

AdminLessonInfoTable.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    photoUrl: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    visibility: PropTypes.string.isRequired,
  }).isRequired,
};
