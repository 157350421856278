export const Events = {
  LogIn: 'Log In',
  LogOut: 'Log Out',
  SelectModule: 'Select Module',
  ShowLessonPreview: 'Show Lesson Preview',
  DismissLessonPreview: 'Dismiss Lesson Preview',
  SelectLesson: 'Select Lesson',
  ContinueLesson: 'Continue Lesson',
  StartLesson: 'Start Lesson',
  GoToQuestion: 'Go To Question',
  GuessAnswer: 'Guess Answer',
};
