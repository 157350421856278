import React from 'react';
import { useNavigate } from 'react-router-dom';

import ClickableDiv from '../ClickableDiv/ClickableDiv';
import styles from './BackButton.module.css';

export default function BackButton() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <ClickableDiv onClick={handleBack}>
        <h3 className={styles.backText}>{'< Go Back'}</h3>
      </ClickableDiv>
    </div>
  );
}
