import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import Cookies from 'universal-cookie';
import { setUserId, track } from '@amplitude/analytics-browser';

import { COOKIES, Time } from '../../utils/constants';
import { getAuthToken, getMe } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import styles from './LoginCompleteScreen.module.css';
import { Events } from '../../utils/metric-events';

export default function LoginCompleteScreen() {
  const navigate = useNavigate();
  const state = useGlobalState();

  useEffect(() => {
    async function getTokenAndUser() {
      const tokenResponse = await getAuthToken();
      if (tokenResponse.error) {
        return navigate('/');
      }

      const { apiToken } = tokenResponse;
      const userResponse = await getMe({ apiToken });
      if (userResponse.error) {
        return navigate('/');
      }

      state.apiToken = apiToken;
      state.user = userResponse.user;
      setUserId(userResponse.user.id);
      track(Events.LogIn);

      const cookies = new Cookies();
      cookies.set(COOKIES.API_TOKEN, apiToken, {
        path: '/',
        sameSite: 'none',
        maxAge: Time.SECONDS_IN_DAY * 30,
      });

      return navigate('/dashboard');
    }

    getTokenAndUser();
  }, []);

  return (
    <>
      <div className={styles.backgroundImage} />
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h1 className={styles.signInText}>Signing You In</h1>
          <div className={styles.spinnerContainer}>
            <Bars color="#1b1b1b" height={50} width={50} />
          </div>
        </div>
      </div>
    </>
  );
}
