import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const processEnv = env || process.env;

const config = {
  apiUrl: processEnv.REACT_APP_API_URL,
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  cdnUrl: 'https://cdn.raduo.cloud',
  feedbackFormUrl: 'https://docs.google.com/forms/d/e/1FAIpQLScUDwOzzC9cXTjOJE5DXJlNUNGWiJh517AwF-Y2bc4OLXbLUA/viewform',
};

export default config;
