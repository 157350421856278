import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { track, setUserId } from '@amplitude/analytics-browser';

import { revoke } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import { COOKIES } from '../../utils/constants';
import { ClickableDiv } from '../atoms';

import styles from './Navbar.module.css';
import { Events } from '../../utils/metric-events';

export default function Navbar() {
  const navigate = useNavigate();
  const state = useGlobalState();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const { user, apiToken } = state;
  const isLoggedIn = user?.name !== undefined;

  const handleLogout = () => {
    const cookies = new Cookies();
    revoke(apiToken);
    track(Events.LogOut);
    delete state.user;
    delete state.apiToken;
    setUserId(undefined);
    cookies.remove(COOKIES.API_TOKEN, { sameSite: 'none' });
    navigate('/');
  };

  const handleAdminClick = () => {
    navigate('/admin');
  };

  return (
    <>
      <div className={styles.container}>
        <ClickableDiv onClick={() => navigate('/dashboard')} className={styles.logoContainer}>
          <span className={styles.logoText}>raduo</span>
        </ClickableDiv>
        <ClickableDiv
          className={styles.nameContainer}
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <span className={styles.nameText}>
            {isLoggedIn ? user.name : 'Log In'}
          </span>
        </ClickableDiv>
      </div>
      {showUserMenu && (
        <>
          <div className={styles.userMenu}>
            {user?.isAdmin === true && (
              <ClickableDiv className={styles.userMenuOption} onClick={handleAdminClick}>
                <h4>Admin</h4>
              </ClickableDiv>
            )}
            <ClickableDiv className={styles.userMenuOption} onClick={handleLogout}>
              <h4>Logout</h4>
            </ClickableDiv>
          </div>
          <ClickableDiv className={styles.dismissHandler} onClick={() => setShowUserMenu(false)} />
        </>
      )}
    </>
  );
}
