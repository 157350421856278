import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';

import { getLesson } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import AdminLessonInfoTable from './components/AdminLessonInfoTable';
import AdminLessonQuestions from './components/AdminLessonQuestions';
import { NavbarScreen } from '../../components';
import { BackButton, ClickableDiv } from '../../components/atoms';
import styles from './AdminSingleLessonScreen.module.css';
import AdminLessonDidactic from './components/AdminLessonDidactic';
import { Visibility } from '../../utils/constants';

export default function AdminSingleLessonScreen() {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const state = useGlobalState();
  const [lesson, setLesson] = useState();
  const [didactic, setDidactic] = useState(null);

  const loadLessonData = async () => {
    const { apiToken } = state;
    const res = await getLesson({
      apiToken,
      lessonId,
      questionVisibilities: [
        Visibility.PUBLIC,
        Visibility.HIDDEN,
      ],
    });
    setLesson(res.lesson);
    setDidactic(res.didactic);
  };

  useEffect(() => {
    loadLessonData();
  }, []);

  if (lesson === undefined) {
    return (
      <NavbarScreen requireAuth>
        <BackButton />
        <Bars color="#1b1b1b" height={50} width={50} />
      </NavbarScreen>
    );
  }

  const handleEditClick = () => {
    navigate('edit');
  };

  const handleAddQuestionClick = () => {
    navigate('question/new');
  };

  return (
    <NavbarScreen requireAuth>
      <BackButton />
      <div className={styles.centeredContainer}>
        <h1>Lesson Info</h1>
      </div>
      <div className={styles.centeredContainer}>
        <ClickableDiv className={styles.button} onClick={handleEditClick}>
          <h2 className={styles.buttonText}>Edit</h2>
        </ClickableDiv>
      </div>
      <AdminLessonInfoTable lesson={lesson} />
      <div className={styles.centeredContainer}>
        <h1>Lesson Didactic</h1>
      </div>
      <AdminLessonDidactic markdown={didactic} lessonId={lesson.id} refresh={loadLessonData} />
      <div className={styles.centeredContainer}>
        <h1>Lesson Questions</h1>
      </div>
      <AdminLessonQuestions questions={lesson.questions} refresh={loadLessonData} />
      <div className={styles.centeredContainer}>
        <ClickableDiv className={styles.button} onClick={handleAddQuestionClick}>
          <h2 className={styles.buttonText}>+ Add Question</h2>
        </ClickableDiv>
      </div>
    </NavbarScreen>
  );
}
