import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import { getLesson, createLesson, updateLesson } from '../../utils/api';
import { useGlobalState } from '../../utils/hooks';
import { NavbarScreen } from '../../components';
import { BackButton, ClickableDiv } from '../../components/atoms';
import styles from './AdminNewOrEditLessonScreen.module.css';
import { Visibility } from '../../utils/constants';

export default function AdminNewOrEditLessonScreen({ isEdit }) {
  const state = useGlobalState();
  const { lessonId, moduleId: moduleIdParam } = useParams();
  const navigate = useNavigate();

  const [moduleId, setModuleId] = useState(moduleIdParam);
  const [title, setTitle] = useState();
  const [visibility, setVisibility] = useState(Visibility.PUBLIC);
  const [level, setLevel] = useState();
  const [position, setPosition] = useState();
  const [description, setDescription] = useState();
  const [photoUrl, setPhotoUrl] = useState();
  const [loaded, setLoaded] = useState(!isEdit);
  const [error, setError] = useState();

  if (isEdit) {
    useEffect(() => {
      const { apiToken } = state;
      getLesson({ apiToken, lessonId })
        .then((res) => {
          setTitle(res.lesson.title);
          setVisibility(res.lesson.visibility);
          setDescription(res.lesson.description);
          setPhotoUrl(res.lesson.photoUrl);
          setLevel(res.lesson.level);
          setPosition(res.lesson.position);
          setModuleId(res.lesson.moduleId);
          setLoaded(true);
        });
    }, []);
  }

  if (!loaded) {
    return (
      <NavbarScreen requireAuth>
        <BackButton />
        <h1>Loading Lesson</h1>
      </NavbarScreen>
    );
  }

  const handleSubmit = () => {
    const lessonData = {
      title,
      description,
      photoUrl,
      level,
      position,
      moduleId,
      visibility,
    };
    const { apiToken } = state;

    if (isEdit) {
      updateLesson({ apiToken, lessonId, updatedLessonData: lessonData })
        .then((res) => {
          if (res.errors) {
            setError(JSON.stringify(res.errors));
          } else if (res.error) {
            setError(JSON.stringify(res.error));
          } else {
            navigate(-1);
          }
        });
    } else {
      createLesson({ apiToken, lessonData })
        .then((res) => {
          if (res.errors) {
            setError(JSON.stringify(res.errors));
          } else {
            navigate(-1);
          }
        });
    }
  };

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.value);
  };

  return (
    <NavbarScreen requireAuth>
      <BackButton />
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h1>{isEdit ? 'Edit Lesson' : 'New Lesson'}</h1>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Visibility</span>
          </div>
          <select name="type" id="type-select" onChange={handleVisibilityChange} value={visibility}>
            <option value={Visibility.PUBLIC}>Public</option>
            <option value={Visibility.PREVIEW}>Preview</option>
            <option value={Visibility.HIDDEN}>Hidden</option>
          </select>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Title</span>
          </div>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Description</span>
          </div>
          <input value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Photo URL</span>
          </div>
          <input value={photoUrl} onChange={(e) => setPhotoUrl(e.target.value)} />
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Level</span>
          </div>
          <input value={level} type="number" onChange={(e) => setLevel(parseInt(e.target.value, 10))} />
        </div>
        <div className={styles.inputRow}>
          <div className={styles.label}>
            <span>Position</span>
          </div>
          <input value={position} type="number" onChange={(e) => setPosition(parseInt(e.target.value, 10))} />
        </div>
        <div className={styles.createContainer}>
          <ClickableDiv onClick={handleSubmit}>
            <h3 className={styles.createButton}>{isEdit ? 'Update Lesson' : 'Create Lesson'}</h3>
          </ClickableDiv>
          {error !== undefined && (
            <span className={styles.errorText}>{error}</span>
          )}
        </div>
      </div>
    </NavbarScreen>
  );
}

AdminNewOrEditLessonScreen.propTypes = {
  isEdit: PropTypes.bool,
};

AdminNewOrEditLessonScreen.defaultProps = {
  isEdit: false,
};
