import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

import { useGlobalState } from '../../utils/hooks';
import { getQuestion } from '../../utils/api';
import { QuestionType } from '../../utils/constants';
import NewOrEditTextQuestion from './components/NewOrEditTextQuestion';
import NewOrEditPhotoQuestion from './components/NewOrEditPhotoQuestion';
import NewOrEditSectionPhotoQuestion from './components/NewOrEditSectionPhotoQuestion';
import { BackButton } from '../../components/atoms';
import { NavbarScreen } from '../../components';
import styles from './AdminNewOrEditQuestionScreen.module.css';

export default function AdminNewOrEditQuestionScreen({ isEdit }) {
  const { lessonId, questionId } = useParams();
  const { apiToken } = useGlobalState();

  const [type, setType] = useState(QuestionType.TEXT);
  const [loading, setLoading] = useState(isEdit);

  function handleTypeChange(event) {
    setType(event.target.value);
  }

  if (isEdit) {
    useEffect(() => {
      getQuestion({ apiToken, questionId })
        .then(({ question }) => {
          setType(question.type);
          setLoading(false);
        });
    }, []);
  }

  if (!isEdit || !loading) {
    let questionComponent;
    switch (type) {
      case QuestionType.PHOTO:
        questionComponent = <NewOrEditPhotoQuestion lessonId={lessonId} questionId={questionId} />;
        break;
      case QuestionType.TEXT:
        questionComponent = <NewOrEditTextQuestion lessonId={lessonId} questionId={questionId} />;
        break;
      case QuestionType.SECTION_PHOTO:
        questionComponent = (
          <NewOrEditSectionPhotoQuestion lessonId={lessonId} questionId={questionId} />
        );
        break;
      default:
        questionComponent = null;
    }

    if (questionComponent !== null) {
      return (
        <NavbarScreen requireAuth>
          <BackButton />
          <div className={styles.outerContainer}>
            <div className={styles.container}>
              <div className={styles.titleContainer}>
                <h1>{isEdit ? 'Edit Question' : 'New Question'}</h1>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.label}>
                  <span>Type*</span>
                </div>
                <select name="type" id="type-select" onChange={handleTypeChange} value={type} disabled={isEdit}>
                  <option value="TEXT">Text</option>
                  <option value="PHOTO">Photo</option>
                  <option value="SECTION_PHOTO">Section Photo</option>
                </select>
              </div>
              {questionComponent || null}
            </div>
          </div>
        </NavbarScreen>
      );
    }
  }

  return (
    <NavbarScreen requireAuth>
      <BackButton />
      <div className={styles.titleContainer}>
        <Oval width="50" height="50" />
      </div>
    </NavbarScreen>
  );
}

AdminNewOrEditQuestionScreen.propTypes = {
  isEdit: PropTypes.bool,
};

AdminNewOrEditQuestionScreen.defaultProps = {
  isEdit: false,
};
